.modal-open {
  overflow: auto !important;
}

.modal-title {
  font-weight: 200;
  font-size: 4rem;
}

.subscription-fields > div {
  width: 48%;
}

.select-field {
  top: -1px;
}

.select-field > div {
  border: none !important;
}

// .modal-dialog-centered {max-width: 750px}
