@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");

html {
  font-size: 62.5%;
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1400px;
  }
}

@media (min-width: 1800px) {
  .container {
    max-width: 1780px;
  }
}
