.form-wrapper-rep-location {
  h5 {
    margin-left: 5.8rem;
  }
  .icon-and-text-wrapper {
    padding-bottom: 2rem;
    @media screen and (max-width: 768px) {
      text-align: center;
      margin-left: 0;
    }
    margin-left: 5.8rem;
    a {
      font-weight: 400;
      font-size: 1.6rem;
      color: #000000;
      &:hover {
        font-weight: 500;
      }
    }
    p {
      font-family: Montserrat;
      font-size: 1.6rem;
      letter-spacing: 0.1rem;
      font-weight: 500;
      margin-bottom: 2rem;
    }
    svg {
      margin-bottom: 2rem;
    }
  }
}
/*Media Queries*/
@media (max-width: 575.98px) {
  .form-wrapper-rep-location {
    h5 {
      margin-left: 0;
      padding-bottom: 2rem;
    }
  }
}
