.catalogs-frame {
  width: 100%;
  height: 90rem;
}
@media screen and (max-width: 1024px) {
  .catalogs-frame {
    width: 100%;
    height: 45rem;
  }
}
@media screen and (max-width: 480px) {
  .catalogs-frame {
    width: 100%;
    height: 28rem;
  }
}
