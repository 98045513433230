::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #c2c2c2 !important;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #c2c2c2 !important;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #c2c2c2 !important;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #c2c2c2 !important;
}

.form-check-input {
  position: absolute;
  margin-top: -3px;
  margin-left: -1.25rem;
  border-radius: 0 !important;
  border: none;
  appearance: none;
  background-size: contain;
}

.form-check-input:checked {
  outline: none;
  background-size: contain;
}

input[type="text"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="datetime"],
input[type="email"] {
  border: 0;
  border-bottom: 1px solid #2e2e2d;
  border-radius: 0;
  font-size: 1.3rem;
  padding: 0;
}

.select-field {
  border-bottom: 1px solid #2e2e2d !important;
  border-radius: 0;
  font-size: 1.2rem;
  padding: 0;
}

.select-field input[type="text"] {
  height: 15px;
}

input[type="text"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="datetime"],
input[type="email"] {
  background: #fff;
  background-clip: padding-box;
  border-radius: 1px;
  font-family: "Raleway", sans-serif;
  height: 32px;
  line-height: 1.42857143;
  padding: 0 9px;
  vertical-align: baseline;
  width: 100%;
  box-sizing: border-box;
}
