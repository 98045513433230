.has-top-border-quote-request {
  border-top: 0.2rem solid #e1e1e1;
}
.has-border-quote-request {
  border-bottom: 0.2rem solid #e1e1e1;
}
.details-holder {
  .quote-label {
    background-color: white !important;
  }
  #collapseDetails {
    .details-table {
      .details-table-question {
        font-family: Montserrat !important;
        font-size: 1.6rem;
        font-weight: 600;
      }
      .details-table-answer {
        font-family: Montserrat !important;
        font-size: 1.6rem;
        font-weight: 500;
      }
    }
  }
  .md-accordion {
    .price-quote-request {
      font-family: Montserrat !important;
      font-size: 1.6rem;
      font-weight: 600;
    }
    .card {
      width: unset !important;
      .card-body {
        width: unset !important;
      }
    }
  }
  .item-qty-container {
    .quote-item-trash-icon {
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
    .number-input {
      margin-top: 2rem;
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
      .qty-quote-label {
        position: absolute;
        top: -2rem;
        z-index: 99;
        background-color: white !important;
        color: #000000;
        font-size: 1.2rem;
        left: 1rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
      .qty-click-minus {
        position: absolute;
        top: 25%;
        left: 15%;
        cursor: pointer;
      }
      .qty-click-plus {
        position: absolute;
        top: 25%;
        right: 15%;
        cursor: pointer;
      }
    }
  }
}
