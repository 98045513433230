@import "./hotspotCard/hotspotCard";
.hotspotContentContainer {
  .view {
    overflow: visible;
  }
  .mask.hotSpot {
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 2.7rem;
    height: 2.7rem;
    transform: rotate(180deg);
    overflow: visible !important;
    background-attachment: fixed;
    &.hotspot-active {
      z-index: 2;
      .hotspot-circle {
        transform: rotate(45deg);
        overflow: visible;
        svg {
          #ico_hotspot_closed {
            circle {
              opacity: 1;
            }
          }
        }
      }
    }
    &:hover {
      a {
        color: #1e1b3b;
      }
    }
  }
  .hotspot-inactive {
    .hotspot-circle {
      background-color: rgba(white, 0.2);
      z-index: 0;
    }
  }
  .hotspot-circle {
    left: -2rem;
    width: 5rem;
    height: 5rem;
    top: -2rem;
    cursor: pointer;
    width: 2.7rem;
    height: 2.7rem;
    border-radius: 100%;
    background: #fff !important;
    box-shadow: 0 0 0 4px rgba(251, 252, 245, 0.3) !important;
    z-index: 10;
    padding: 0.2rem;
    font-size: 1.6rem;
    opacity: 1 !important;
    -webkit-animation: pulsing 2s infinite;
    animation: pulsing 2s infinite;
    text-align: center;
    svg {
      overflow: hidden;
    }
  }
  .img-hotspot {
    position: absolute;
    top: 0;
    left: 0;
    width: 3.7rem;
    height: 3.7rem;
    cursor: pointer !important;
  }
  .img-hotspot2 {
    width: 3.7rem;
    height: 3.7rem;
    cursor: pointer;
  }
  .img-hotspotdetail {
    max-width: 12.5rem;
    max-height: 10rem;
    display: inline-block;
    opacity: 1;
    cursor: pointer;
  }
}
/*Media Queries*/
@media screen and (max-width: 600px) {
  .hotspot-container {
    .hotspot-card {
      .mask {
        height: 8rem;
      }
    }
  }
}
