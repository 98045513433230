.border-button {
  background-color: transparent !important;
  box-shadow: none !important;
  border: 0 solid #2e2e2d;
  cursor: pointer;
  display: inline-block;
  font-family: "RalewayLight", "Raleway", sans-serif;
  font-weight: 400;
  padding: 13px 25px;
  font-size: 1.7rem;
  line-height: 1.7rem;
  box-sizing: border-box;
  vertical-align: middle;
  position: relative;
  min-width: 21rem;
  text-align: center;
  background-color: transparent;
  margin-top: 2rem;
  color: #fff;
}

.border-button.invert {
  color: #2e2e2d;
  border: 0 solid #fff;
}

.border-button::before {
  border: 1px solid #fff;
  border-top: 0;
  border-right: 0;
  top: auto;
  bottom: 0;
  left: 0;
  content: "";
  position: absolute;
  right: 0;
  width: 24px;
  height: 25px;
  transition: background-image 0.3s, width 0.3s;
}

.border-button.invert::before {
  border: 1px solid #2e2e2d;
  border-top: 0;
  border-right: 0;
}

.border-button.invert::after {
  border: 1px solid #2e2e2d;
  border-bottom: 0;
  border-left: 0;
}

.border-button::after {
  border: 1px solid #fff;
  border-bottom: 0;
  border-left: 0;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 25px;
  transition: background-image 0.3s, width 0.3s;
}

.border-button:hover:before,
.border-button:hover:after {
  width: calc(100% - 0px);
  height: calc(100% - 0px);
}

.border-button:hover {
  background: 0 0;
  color: #fff;
  text-decoration: none;
}

.border-button.invert:hover {
  color: #2e2e2d;
}
