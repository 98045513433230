.menu-font-family {
  a {
    font-family: SangBleuSunriseRegular !important;
  }
}
.parent-nav-item,
.category-title {
  position: relative;
  width: fit-content;
  cursor: pointer;
  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    width: 100%;
    background: #f29823;
    transform: scaleX(0);
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transform-origin: top left;
  }
  &.active:after {
    transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    -o-transform: scaleX(1);
  }
}
.submenu {
  position: fixed;
  left: 0;
  background: $white;
  z-index: 1;
  width: 100%;
  overflow: auto;
  max-height: calc(100vh - 10rem);
  box-shadow: 0 50px 50px 0 rgba(23, 23, 23, 0.21);
  border: solid 1px #979797;
  .children-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  // closed menu
  // display: none;
  .pages-part {
    padding: 3rem 4rem 4rem 8.5rem;
    border-right: $darkGrayBorder;
    &.first {
      ul {
        > li {
          font-size: 1.8rem;
          &:not(:last-of-type) {
            margin-bottom: 3rem;
          }
          a {
            font-size: 1.8rem;
          }
        }
      }
    }
    ul {
      padding-left: 0;
      list-style: none;
    }

    a {
      font-size: 1.2rem;
      line-height: 2.5;
      color: #49494f;
      text-transform: initial;
      font-weight: 400;
      padding: 0;
    }

    .submenu-title {
      padding-bottom: 1rem;

      .h6 {
        color: #211f44;
        font-weight: 500;
      }
    }

    .view-all-box {
      justify-content: flex-end;
      padding-top: 5rem;

      a {
        text-transform: uppercase;
        font-weight: 700;
      }
    }
  }

  .submenu-right {
    height: 100%;

    > div {
      height: 100%;
    }

    .product-image {
      padding-top: 3.6rem !important;
      padding-bottom: 2rem;
    }

    .product-back {
      height: 100%;
      width: 100%;
      background-size: cover !important;
      background-position: center !important;
      background-repeat: no-repeat !important;
    }
  }
}

@media (max-width: 1199px) {
  .submenu {
    .pages-part {
      padding: 1.5rem;
    }
  }
}

@media (max-width: 991px) {
  .submenu {
    // closed submenu on mobile
    display: none;
    top: 6.6rem;
    max-height: calc(100vh - 6.6rem);

    .submenu-right {
      display: none;
    }

    .pages-part {
      padding: 0 1.5rem 3rem;
      border-right: 0;

      .submenu-title {
        .h6 {
          font-size: 1.4rem;
          text-align: center;
          padding: 2rem;
          background: #f6f7f0;
          border-bottom: $lightGrayBorder;
          margin: 0 -1.5rem;
          position: relative;

          &:before {
            content: url(../../assets/images/left-arrow.png);
            position: absolute;
            left: 2rem;
            top: 50%;
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
          }
        }
      }

      ul {
        padding: 0 1.5rem;

        a {
          line-height: 4;
        }
      }

      .view-all-box {
        display: none !important;
      }
    }
  }
}
