.text-part-with-image {
  padding: 10rem 0;
  img {
    width: 100% !important;
  }

  .text-column {
    margin: 0 auto;
    max-width: calc(100% - 74rem);
  }

  .h1 {
    max-width: calc(100% - 120rem);
    min-width: 40%;
    margin-left: 15%;
    color: #15152a;
    margin-bottom: 3rem;
    p {
      font-family: SangBleuSunriseRegular;
      font-size: 4.2rem;
      font-weight: 300;
      line-height: 1.38;
      letter-spacing: 2px;
      color: #15152a;
    }
  }

  p {
    color: #49494f;
    margin-bottom: 2rem;
  }

  .h6 {
    color: #1e1b3b;
  }

  img {
    width: 115rem;
    margin: 6rem 0 4rem auto;
    display: block;
  }

  a {
    display: block;
    margin: 0 1rem 0 auto;
    max-width: fit-content;
  }
}

.large-image {
  max-width: calc(100% - 74rem);
  margin: 0 auto;
}

.large-image img {
  display: inline-block;
}

@media (min-width: 1981px) {
  .text-part-with-image {
    .h1 {
      margin-left: 10%;
    }
  }
}

@media (max-width: 1024px) {
  .text-column {
    min-width: 90%;
  }
  .text-part-with-image {
    .h1 {
      min-width: 60%;
      margin-left: 5%;
    }
  }

  .large-image {
    min-width: 100%;
    img {
      min-height: 25.3rem;
      object-fit: cover;
    }
  }
}

@media (max-width: 767px) {
  .text-part-with-image {
    padding: 5rem 0;
    .text-column {
      margin: 0 auto;
      min-width: 85%;
    }

    .h1 {
      font-size: 2.8rem;
      max-width: 85%;
      text-align: left;
      margin: 0 auto 3rem;
    }

    p {
      font-size: 1.6rem;
      text-align: left;
    }

    .h6 {
      font-size: 2.4rem;
      text-align: left;
    }

    img {
      margin: 5rem auto 3rem;
    }

    a {
      margin: 0 auto;
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}
