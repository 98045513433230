.error-page {
  @media screen and(max-width: 800px) {
    .error-page {
      display: flex;
      justify-content: center;
    }
  }
  margin-top: 30rem;
  &.container {
    max-width: 133rem;
  }
  .error-page-text {
    h4 {
      font-weight: 500;
      font-size: 2rem;
      margin-bottom: 2rem;
      font-family: Montserrat;
      color: #1e1b3b;
    }

    .custom-list {
      font-weight: 300;
      margin-top: 2rem;
      font-size: 1.6rem;
      font-family: Montserrat;
      color: #1e1b3b;
      list-style-type: none;
    }
  }
  .error-page-buttons {
    margin-top: 5rem;
    margin-bottom: 10rem;
  }
}

@media screen and(max-width: 800px) {
  .error-page {
    text-align: center;
  }
}
