.main-row {
  @import "./item/quoteRequestItem";
  // border-bottom: 2px solid #e1e1e1;
  margin-bottom: 2%;
  margin-top: 0 !important;
}

.delete-div {
  :hover {
    cursor: pointer;
  }
}

.left-column {
  margin-top: 17rem;
  padding: 0 5%;
  margin-bottom: 2%;
  @media screen and(max-width: 1400px) {
    margin-top: 20rem;
  }
  @media screen and(max-width:992px) {
    margin-top: 10rem;
  }

  h1 {
    // border-bottom: 2px solid #e1e1e1;
    padding-bottom: 2rem;
    padding-top: 3%;
    font-size: 40px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2.5px;
    color: #1f1e3f;
    font-family: SangBleuSunriseRegular !important;
    @media screen and(max-width: 600px) {
      font-size: 2.4rem;
      margin-bottom: 2rem;
    }
    @media (max-width: 575.98px) {
      font-size: 2rem;
    }
  }
}

.right-column {
  @import "./quoteForm/quoteForm";
  margin-top: 17rem;
  padding: 0 5%;
  background-color: $white;
  @media screen and(max-width: 1400px) {
    margin-top: 20rem;
  }
  @media screen and(max-width: 1200px) {
    margin-top: 0;
    text-align: center;
    .md-accordion {
      display: flex;
      text-align: center;
      justify-content: center;
    }
  }
  h2 {
    border-bottom: 2px solid #e1e1e1;
    padding-bottom: 8% !important;
    padding-top: 8%;
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #211f44;
  }

  .input-label {
    background: #fbfcf5 !important;
  }
}
.quote-request-item {
  .main-image {
    img {
      // padding-top: 30%;
      width: 100%;
    }
    @media screen and(max-width: 768px) {
      display: flex;
      justify-content: center;
      img {
        height: 12rem;
        width: 12rem;
      }
    }
  }
}

.title-mobile {
  display: none;
}

.product-details {
  display: inline-block;
  border-bottom: 2px solid #e1e1e1;
  padding-bottom: 3%;
}

.product-title {
  display: inline-block;

  h2 {
    @media screen and(max-width: 600px) {
      font-size: 1.4rem;
      margin-left: 0 !important;
    }
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: #211f44;
    margin-left: -15px !important;
  }
}

.number-input {
  border: $lightGrayBorder;
  max-width: 6rem;
  position: relative;
  display: inline-block;

  input {
    border: 0;
    outline: 0;
    padding: 0;
    text-align: center;
  }

  button {
    border: 0;
    background: transparent;
  }
}

.edit-delete {
  display: inline-block;
  position: absolute;
  right: 0;
}

.details-row {
  border-bottom: 2px solid #e1e1e1;
}

.md-accordion .card {
  width: 100% !important;
  border-top: none !important;
  border-bottom: none !important;
}

.md-accordion .card .card-body {
  width: 100% !important;
}

.md-accordion {
  h2 {
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #211f44;
  }
}

.md-accordion .card .card-header {
  border-bottom: 2px solid #e1e1e1;
  margin-bottom: 4%;
}

.icon-collapse {
  display: none;
}

.details-table {
  td {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: 0.1px;
    color: #1f1e3f;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    border-top: none;
    @media screen and(max-width: 600px) {
      font-size: 1.2rem;
    }
  }
}

.md-form textarea.md-textarea + label {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.75px;
}

.save-quote {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.88px;
  color: #1f1e3f;
}

.contact-information {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: 1.25px;
  color: #211f44;
  margin-left: 0px !important;
  border-bottom: 2px solid #e1e1e1;
}

.contact-information-right {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: 1.25px;
  color: #211f44;
}

.upload-btn {
  .btn-sm {
    margin-top: 10%;
    margin-left: -8px;
  }
}

.btn {
  margin: 0;

  &.btn-lg {
    padding: 1.8rem;
    font-size: 1.6rem;
    width: 100% !important;
    margin-bottom: 15%;
  }
}

.md-form textarea.md-textarea + label {
  background: #fbfcf5 !important;
}

.upload-format {
  opacity: 0.7;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.07px;
}

.collapse-right {
  background: #fbfcf5 !important;
}

.number-input input[type="number"] {
  border: 1px solid #979797 !important;
}

.quote-input {
  padding: 1.5rem 3rem !important;
  font-size: 1.4rem;
  letter-spacing: 0.88px;
  line-height: 1.9rem;
  border: $darkGrayBorder !important;

  &:focus {
    border: 1px solid #353452 !important;
    box-shadow: 0 0 !important;
  }
}

.error-quote {
  border: 1px solid #f83535 !important;

  &:focus {
    border: 1px solid #f83535 !important;
    box-shadow: 0 0 !important;
  }
}

label.active.error-quote-label {
  color: #f83535 !important;
}

.md-form.phone {
  .quote-label {
    position: absolute;
    top: -1.5em!important;
  }
  input.quote-input {
    box-sizing: border-box;
    min-height: 50px;
}
}

.quote-label {
  position: relative;
  top: -1rem !important;
  left: 2rem !important;
  text-transform: uppercase;
  background: #fafcf5 !important;
  z-index: 5;
  padding: 0 0.5rem;
  font-family: Montserrat;
  font-weight: 500;
  line-height: normal;
  color: #000000;
}

.quote-select {
  .select-wrapper > label.mdb-main-label {
    position: relative;
    z-index: 5 !important;
    top: -6.7rem !important;
    left: 6rem !important;
    text-transform: uppercase;
    background: #fafcf5 !important;
    padding: 0 0.5rem !important;
    font-family: Montserrat !important;
    font-weight: 500 !important;
    line-height: normal !important;
    color: #000000;
  }

  .select-wrapper span.caret {
    top: 35% !important;
  }
}

label.active.quote-label {
  font-size: 12px !important;
}

.quote div.btn.btn-primary.btn-sm.float-right {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.88px;
  color: #ffffff;
  background-color: #f29823 !important;
  height: 50px;

  &:hover {
    @include gradient(to right, $orangeList);
    box-shadow: 0 0;
  }
}

.quote {
  position: relative;
  .file-field .file-path-wrapper {
    overflow: visible;
  }
  .file-field .file-path-wrapper input {
    float: left;
    background: transparent;
    width: 50%;
    height: 50px !important;
    border: $darkGrayBorder !important;
    overflow: visible !important;
  }

  .file-field .file-path-wrapper input::placeholder {
    font-family: Montserrat;
    font-size: 14px !important;
    font-weight: 300;
    font-stretch: normal;
    letter-spacing: 0.88px;
    color: #000000;
    padding-left: 1.5rem;
  }

  .upload-label {
    position: absolute;
    font-family: Montserrat;
    background: #fafcf5 !important;
    z-index: 5;
    top: -0.5rem;
    left: 3rem;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.75px;
    color: #000000;
    @media screen and(max-width: 600px) {
      font-size: 0.9rem;
      left: 1.5rem;
      letter-spacing: 0;
    }
  }
}
.error-message {
  font-size: 12px;
  color: #f83535 !important;
  font-family: Montserrat;
}
/*Media Queries*/
@media (max-width: 575.98px) {
  .quote-request-wrapper {
    .main-row {
      border-bottom: none;
      > .row {
        border-bottom: none !important;
      }
    }
    .left-column {
      .md-accordion {
        .card {
          border-bottom: none;
          &:first-child {
            border-top: none !important;
          }
          .collapse {
            &.show {
              border-bottom: none;
            }
          }
          .card-header {
            padding: 1.5rem 0;
            border-bottom: none;
            margin-bottom: 0;
            h5 {
              h2 {
                font-size: 1.6rem;
              }
            }
          }
        }
      }
    }
    .right-column {
      h2 {
        font-size: 1.8rem;
      }
      .md-accordion {
        .card {
          .card-header {
            padding: 1.5rem 0;
            h5 {
              h3 {
                font-size: 1.6rem;
              }
            }
          }
        }
      }
    }
  }
}
