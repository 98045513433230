.add-to-quote-request {
  .login-to-add-to-quote {
    font-family: "Montserrat";
    font-size: 1.4rem;
    @media screen and(max-width: 1200px) {
      font-size: 1.2rem;
    }
    font-weight: 600;
    color: #1f1e3f;
    text-transform: uppercase;
    background-color: white !important;
    border: 1.5px solid #1f1e3f;
    max-width: 40rem;
    width: 100%;
    &:hover {
      border: 1.5px solid #f29823;
      transition: 20ms ease-in;
      background-color: #f29823 !important;
      font-weight: 600;
      color: white;
    }
  }
  .large-button {
    margin-bottom: 0;
  }
  .heart-icon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    :hover {
      cursor: pointer;
      transform: scale(1.1);
      transition: 0.3s all;
    }
  }
}
