.site-map {
  padding-top: 10rem;

  .site-map-container {
    padding: 10rem 0 20rem;

    .h1 {
      color: #15152a;
      font-weight: 400;
    }

    .h4 {
      min-height: 8.2rem;
      margin-bottom: 3rem;
      color: #211f44;
      margin-top: 5rem;
    }

    p {
      font-weight: 600;
      color: #49494f;
      margin-bottom: 1rem;
    }

    ul {
      margin-bottom: 3rem;

      a {
        font-size: 1.8rem;
        font-weight: 400;
        text-transform: initial;
        color: #49494f;
      }
    }
  }
}

.second-heading {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.44;
  letter-spacing: 1px;
  color: #49494f;
}
/*Media Queries*/
@media (min-width: 1500px) {
  .site-map {
    .container {
      max-width: 1500px;
    }
  }
}

@media (max-width: 991px) {
  .site-map {
    padding-top: 6.6rem;

    .site-map-container {
      padding: 5rem 0 10rem;

      .h1 {
        font-size: 3rem;
        margin-bottom: 2rem;
      }

      .h4 {
        min-height: auto;
        margin-top: 2rem;
        font-size: 2.4rem;
      }

      ul {
        margin-bottom: 2rem;

        a {
          font-size: 1.6rem;
        }
      }
    }
  }
}
@media (max-width: 575.98px) {
  .site-map {
    .site-map-container {
      h1 {
        &.h1 {
          font-size: 2rem;
        }
      }
      .row-container {
        margin-top: 0;
        h2 {
          &.h4 {
            font-size: 1.8rem;
          }
        }
        ul {
          li {
            a {
              font-size: 1.4rem;
            }
          }
        }
      }
    }
  }
}
