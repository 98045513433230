.hotspot-card {
  width: 20rem;
  max-width: 20rem;
  position: absolute;
  opacity: 1;
  z-index: 0;
  border-radius: 0;
  height: 13rem;
  max-height: 12rem;
  border: 0.1rem solid #d3d3d3;
  .view {
    width: 50%;
    > div {
      text-align: center;
      max-width: 12rem;
      max-height: 12rem;
    }
    .card-img {
      object-fit: contain;
      height: 100%;
      width: 100%;
      z-index: 1;
      @media screen and(max-width: 600px) {
        width: 6rem;
        height: 7.5rem;
      }
    }
  }
  .card-body {
    width: 50%;
    z-index: 1;
    padding: 0;
    text-align: center;
    .card-title {
      margin-left: 0;
      &.product-name {
        font-size: 1.4rem;
        line-height: 2rem;
        letter-spacing: 0.042rem;
        opacity: 1;
        margin-bottom: 1rem;
        @media screen and(max-width: 600px) {
          font-size: 1.2rem;
        }
      }
    }
  }
  .hotspot-card-triangle {
    width: 30px;
    height: 30px;
    background: white;
    position: absolute;
    left: -1.2rem;
    top: 35%;
    transform: rotate(45deg) !important;
  }
  &.cardContainer {
    transform: rotate(180deg) translateX(115%);
    bottom: -4rem;
  }
  &.cardContainerBottom {
    transform: rotate(180deg) translateX(115%);
    top: -5rem;
  }
  &.cardContainerBottomRight {
    transform: rotate(180deg) translateX(-25%);
    top: -5rem;
    .hotspot-card-triangle {
      left: auto;
      right: -1rem;
    }
  }

  &.cardContainerRight {
    transform: rotate(180deg) translateX(-25%);
    bottom: -4rem;
    .hotspot-card-triangle {
      left: auto;
      right: -1rem;
    }
  }
}
/*Media Queries*/
@media screen and (max-width: 600px) {
  .hotspot-card {
    flex-direction: column !important;
    width: 12rem;
    height: auto;

    &.cardContainer {
      transform: rotate(180deg) translateX(40%);
      bottom: 5rem;
      .hotspot-card-triangle {
        left: 35%;
        top: -0.9rem;
      }
    }
    &.cardContainerBottom {
      top: -4.5rem;
    }
    &.cardContainerRight {
      transform: rotate(180deg) translateX(-40%);
    }
    &.cardContainerBottomRight {
      transform: rotate(180deg) translateX(-40%);
      top: -4rem;
    }
  }
}
