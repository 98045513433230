.general-technical-information {
  padding-top: 10rem;
  .technical-info-text-wrapper {
    max-width: 140rem;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
  @media (max-width: 992px) {
    padding-top: 7rem;
  }
  .general-technical-top {
    .col-with-text {
      background-color: #2f3181;
    }
  }

  .imageloader-loaded {
    height: 100%;
    width: 100%;
  }
  .general-technical-left-image,
  .general-technical-right-image {
    width: 100% !important;
    height: 40rem;
    @media screen and(max-width: 1024px) {
      height: 30rem;
    }
  }
  .ol-connected.withNumber li:before {
    z-index: 5;
  }
}
/*Media Queries*/
@media (max-width: 800px) {
  .general-technical-information {
    .general-technical-top {
      display: grid !important;
      grid-template-columns: 50%;
      .col-with-image {
        &:nth-child(2) {
          order: 2;
        }
      }
      .col-with-text {
        order: 3;
        grid-column-start: 1;
        grid-column-end: 5;
        height: 20rem;
        h1 {
          height: 50%;
        }
      }
    }
  }
}
@media (max-width: 575.98px) {
  .general-technical-information {
    .general-technical-top {
      .col-with-image {
        img {
          height: 20rem !important;
          object-fit: cover;
        }
      }
      .col-with-text {
        height: 18rem;
        h1 {
          font-size: 2.8rem !important;
          text-align: left !important;
          width: 50% !important;
          justify-content: start !important;
          line-height: normal;
        }
      }
    }
    .technical-info-text-wrapper {
      p {
        font-size: 1.4rem;
      }
    }
  }
}
