.sales-title-wrapper {
  color: #212529;
  font-size: 2.5rem;
  line-height: 1.2;
  font-weight: 300;
}

.find-rep-toolbar {
  .select2-container {
    .select2 {
      .react-select-container {
        .react-select__control {
          border: none;
        }
      }
    }
  }
  .button-container {
    display: flex;
    justify-content: flex-start !important;
    button {
      line-height: 0.9rem;
    }
  }
}
/*Media Queries*/
@media screen and (max-width: 991.98px) {
  .find-rep-toolbar {
    .select2-container {
      margin-top: 1rem;
    }
  }
}
