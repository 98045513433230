.customization-section {
  @import "./video/video";
  padding: 10rem 0 0 0;
  .section-title {
    hr {
      width: 100%;
      border-top: $lightGrayBorder;
    }
    .h5 {
      white-space: nowrap;
      padding: 0 1.8rem;
      letter-spacing: 1.75px;
    }
  }
  .inner-container {
    padding: 10rem 0;
    .text-part {
      height: 100%;
      .h1 {
        color: $black;
        max-width: 40rem;
      }
      p {
        max-width: 50rem;
        margin-left: 7rem;
        margin-top: 3.5rem;
      }
      .btn {
        width: 40rem;
        margin-left: 7rem;
        margin-top: 5.5rem;
        font-weight: 700;
      }
    }
  }

  .image-customization {
    img {
      width: 100% !important;
    }
  }
}

@media (max-width: 1199px) {
  .customization-section {
    .inner-container {
      .text-part {
        p {
          margin-left: 0;
        }
        .btn {
          margin-left: 0;
          width: 100%;
          font-size: 1.4rem;
          margin-top: 2rem;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .customization-section {
    padding: 6rem 1.5rem;
    .h5 {
      font-size: 2rem;
    }
    .inner-container {
      padding: 2rem 0;
      .text-part {
        .h1 {
          font-size: 2.8rem;
          margin-top: 4rem;
        }
        p {
          font-size: 1.4rem;
          margin-top: 2rem;
        }
        a {
          font-size: 1.3rem !important;
        }
      }
    }
  }
}
