@import "./catalog-list/catalog-list";
@import "./catalog-item/catalog-item";

.catalogs-main-banner {
  padding-top: 16rem;
  img {
    width: 100%;
    height: auto;
  }
}
.catalog-container {
  padding-top: 15rem;
}
@media screen and (max-width: 1024px) {
  .catalog-container {
    padding-top: 15rem;
    .catalogs-slider {
      padding-right: 0;
    }
    h1 {
      font-size: 2.4rem;
      margin-top: 4rem;
    }
  }
  // .catalogs-main-banner {
  //   padding-top: 0;
  //   img {
  //     width: 100%;
  //     height: 43rem;
  //     object-fit: cover;
  //     max-width: unset;
  //   }
  // }
}
@media screen and (max-width: 992px) {
  .catalog-container {
    padding-top: 10rem;
  }
}
