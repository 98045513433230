.catalog-list-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
  max-height: 72rem;
  margin-top: 4.5rem;
  -webkit-overflow-scrolling: touch;

  .catalog-list-inner {
    margin: 2rem auto 2rem auto;
    width: 100%;
    padding: 0.8rem;
    max-width: 25rem;
    p {
      margin-top: 1.5rem;
    }
    &.active {
      outline: 1px solid #f19922;
      p {
        color: #f19922;
      }
    }
  }
  img {
    cursor: pointer;
    max-width: 25rem;
    height: auto;
    width: 100%;
  }
}
@media screen and (max-width: 1024px) {
  .catalog-list-wrapper {
    margin: 2rem 0 2rem 0;
    display: grid;
    grid-template-columns: 25rem 25rem 25rem 25rem 25rem;
    gap: 1rem;
    overflow: hidden;
    overflow-x: scroll;
    height: unset;
    .catalog-list {
      padding: 0.1rem;
    }
    img {
      width: 100%;
    }
  }
}
