@import "./videoTutorial/videoTutorial";
.colorpicker-wrap {
  sl-color-picker::part(input),
  sl-color-picker::part(format-button) {
    display: none;
  }
  button,
  button.ColorPicker-MuiButtonBase-root div {
    border-radius: 50%;
    border-width: 2px;
  }
  .tooltip-wrapper {
    text-transform: uppercase;
    font-size: 14px;
  }
  .tooltip {
    background-color: white;
    > div {
      padding: 10px;
      span {
        color: black;
        font-size: 12px;
      }
    }
  }
  #ral-label {
    font-weight: 500;
    font-family: "Montserrat";
    font-size: 1.4rem;
    text-transform: uppercase;
  }
}
.pdp-select-wrapper {
  padding-left: 0;
  max-width: 50.4rem !important;

  .video-tutorial-wrapper {
    display: flex;
    justify-content: flex-end;
    .play-tutorial {
      cursor: pointer;
      span {
        margin-left: 1rem;
      }
    }
  }
  .question-box-tooltip-wrapper {
    position: absolute;
    top: -2rem;
    z-index: 99;
    border: 1px solid #1f1e3f;
    border-radius: 0.5rem;
    font-size: 1.4rem;
    padding: 1rem 1.2rem;
    color: black;
    background-color: #ffffff;
    font-weight: 400;
    p {
      font-family: Montserrat !important;
    }
  }

  .questions-box {
    transition: all 0.5s ease-in-out;
    &:last-child {
      margin-bottom: 3rem;
    }
    &.featured {
      box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.1);
      border-color: transparent;
      padding-left: 1.5rem;
      > div {
        border-color: transparent;
      }
      .option-name {
        span {
          font-size: 1.4rem;
          text-transform: uppercase;
        }
      }
    }
    .input-options-pdp {
      margin-right: 0 !important;
      width: 100%;
      display: flex;
      align-items: center;
      border-bottom: solid 1px #e1e1e1;
      height: 6rem;
      border-left: none;
      border-right: none;
      p {
        text-align: left;
        white-space: nowrap;
        font-size: 1.2rem !important;
        letter-spacing: 0.75px;
        color: #000000;
      }

      .option-name {
        span {
          text-transform: uppercase;
        }
      }

      .one-row {
        width: 50%;
      }
    }

    .input-options-wrapper {
      padding-right: 2.1rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .arrow-right {
        position: absolute;
        right: 10%;
      }
      img {
        margin-right: 1rem;
        max-height: 3rem;
      }
      p {
        text-transform: capitalize;
        white-space: initial;
        line-height: 1.5;
      }
    }
  }

  .catalog-no {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.88px;
    color: #000000;
    margin-bottom: 2.2rem;
  }

  .md-form {
    display: inline-block;
    width: 100% !important;
  }

  .pdp-input {
    background-color: red !important;
    width: 100%;
    display: inline-block !important;
    border: 1px solid #979797 !important;
    height: 50px;
    text-align: center !important;
    margin-bottom: 20px;
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: 10% 55%;
  }

  .pdp-input-error {
    border: 1px solid #f83535 !important;
  }

  .pdp-label-error {
    color: #f83535 !important;
  }

  .pdp-label {
    font-family: Montserrat;
    font-size: 12px !important;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    color: #000000;
    top: -5px;
    background: white;
    left: 1%;
  }
}

@media (max-width: 768px) {
  .pdp-select-wrapper {
    .video-tutorial-wrapper {
      padding: 0;
    }

    .questions-box {
      .input-options-pdp {
        padding-top: 2rem;
        padding-bottom: 2rem;
        height: 10rem;

        .option-name {
          margin-bottom: 0.1rem;
          font-weight: bold;
        }
      }
      &.featured {
        .option-name {
          margin-bottom: 0;
        }
      }
    }
  }
}
