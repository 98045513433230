header {
  @include gradient(to bottom, $blueList);
  position: fixed;
  left: 0;
  right: 0;
  z-index: 9999;
  .bottom-header-wrapper {
    .top-header {
      a {
        color: #fff;
        font-size: 1.2rem;
        font-weight: 600;
        letter-spacing: 0.75px;
      }
      a:not(:last-child) {
        margin-right: 1.5rem;
      }
      a + a {
        padding-left: 1.5rem !important;
        border-left: 1px solid #979797;
      }
    }
  }
  .top-header-outer {
    border-bottom: $darkGrayBorder;

    .top-header {
      justify-content: flex-end;
      padding: 1rem 0;
      .temp-message-header {
        color: #fff;
        margin-right: auto;
        font-size: 1.4rem;
        font-weight: 400 !important;
        a {
          margin-right: 0 !important;
          font-size: 1.4rem;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      a {
        color: #fff;
        font-size: 1.2rem;
        font-weight: 600;
        letter-spacing: 0.75px;
        &:not(:last-child) {
          margin-right: 1.5rem;
        }
        + {
          a {
            padding-left: 1.5rem !important;
            border-left: $darkGrayBorder;
          }
        }
      }
    }
  }

  .btn-transparent.btn.Ripple-parent.dropdown-toggle.user-dropdown::after {
    color: rgba(255, 255, 255, 0.8);
    margin-left: 0;
  }

  .btn-transparent.btn.Ripple-parent.dropdown-toggle.user-dropdown {
    border: none;
    box-shadow: none !important;
  }

  .bottom-header {
    justify-content: space-between;

    .left-part {
      .header-logo-mobile-new {
        img {
          max-height: 4rem;
        }
      }
      .logo {
        padding: 0 5.5rem 0 0;
        margin-right: 0;
      }
    }

    .right-part {
      // margin-top: 2rem;
      :hover {
        cursor: pointer;
      }

      > * {
        + {
          * {
            margin-left: 0rem;
          }
        }
      }

      #nav-icon1 {
        span {
          background: #fff !important;

          &:nth-child(1) {
            top: 1rem;
            height: 2px;
          }

          &:nth-child(2) {
            display: block;
            position: relative;
            top: 17px;
            height: 2px;
          }

          &:nth-child(3) {
            top: 2.5rem;
            height: 2px;
          }
        }
        &.hamburger-button__button {
          top: 0;
        }
      }

      .hamburger-button__checkbox {
        &:checked + #nav-icon1 {
          span {
            &:nth-child(1) {
              top: 2rem;
            }

            &:nth-child(3) {
              top: 2rem;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  header {
    .top-header {
      padding: 1rem 0;
    }

    .bottom-header {
      padding: 0;
    }
  }
}

@media (min-width: 992px) {
  header {
    .bottom-header {
      .mobile-bottom {
        display: none;
      }

      .left-part {
        .nav-link,
        a {
          overflow: visible;
        }
        .mobile-menu-outer {
          > ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;

            > li {
              > .nav-link,
              > a {
                padding: 4rem 1.5rem;
                display: block;
                font-size: 1.4rem;
                color: #fff;
                font-weight: 400;
                text-transform: initial;
                position: relative;
                text-align: center;

                &:after {
                  content: "";
                  display: block;
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  height: 3px;
                  width: 100%;
                  background: $orange;
                  transform: scaleX(0);
                  -webkit-transform: scaleX(0);
                  -moz-transform: scaleX(0);
                  -ms-transform: scaleX(0);
                  -o-transform: scaleX(0);
                  transition: all 0.3s;
                  -webkit-transition: all 0.3s;
                  -moz-transition: all 0.3s;
                  -ms-transition: all 0.3s;
                  -o-transition: all 0.3s;
                  transform-origin: bottom left;
                }

                &:hover {
                  &:after {
                    transform: scaleX(1);
                    -webkit-transform: scaleX(1);
                    -moz-transform: scaleX(1);
                    -ms-transform: scaleX(1);
                    -o-transform: scaleX(1);
                  }
                }
              }

              &.active {
                .nav-link,
                a {
                  &::after {
                    transform: scaleX(1);
                    -webkit-transform: scaleX(1);
                    -moz-transform: scaleX(1);
                    -ms-transform: scaleX(1);
                    -o-transform: scaleX(1);
                  }
                }
              }
            }
          }
        }
      }

      #nav-icon1 {
        display: none;
      }
    }
  }
}

@media (max-width: 991px) {
  body.modal-open
    header
    .bottom-header
    .right-part
    #nav-icon1.hamburger-button__button {
    top: -2px;
  }
  header {
    .top-header {
      display: none !important;
    }
    .bottom-header {
      // padding: 1rem 0;
      height: 7rem;
      .left-part {
        .logo {
          a {
            content: url(../../assets/images/mobile-logo-header.svg);
          }
        }

        .mobile-menu-outer {
          position: fixed;
          top: 6.6rem;
          left: 0;
          width: 100%;
          height: calc(100vh - 6.6rem);
          flex-direction: column;
          display: flex;
          justify-content: space-between;
          // closed menu on mobile
          display: none;

          > ul {
            list-style: none;
            padding-left: 0;
            flex: 1;
            background: #fbfcf5;
            flex-direction: column;
            align-items: flex-start !important;
            margin-bottom: 0;

            > li {
              width: 100%;

              > .nav-link {
                color: #211f44;
                text-transform: initial;
                padding: 1.5rem 2rem;
                border-bottom: $lightGrayBorder;
                font-weight: 400;
                width: 100%;
                position: relative;

                &:after {
                  content: url(../../assets/images/right-arrow.png);
                  position: absolute;
                  right: 2rem;
                  top: 50%;
                  transform: translateY(-50%);
                  -webkit-transform: translateY(-50%);
                  -moz-transform: translateY(-50%);
                  -ms-transform: translateY(-50%);
                  -o-transform: translateY(-50%);
                }
              }
            }
          }

          .mobile-bottom {
            @include gradient(to bottom, $blueList);
            color: #fff;
            padding: 2rem;
            text-transform: uppercase;

            ul {
              padding-left: 0;
              margin-bottom: 0;
              list-style: none;

              span {
                font-size: 1.4rem;
                line-height: 3;
                letter-spacing: 0.88px;

                &.account-mobile {
                  &:before {
                    content: url(../../assets/images/user.svg);
                    margin-right: 2.1rem;
                    position: relative;
                    top: 0.3rem;
                  }
                }

                &.wishlist-mobile {
                  &:before {
                    content: url(../../assets/images/wishlist.svg);
                    margin-right: 1.5rem;
                    position: relative;
                    top: 0.5rem;
                  }
                }
              }
            }
          }
        }
      }

      .right-part {
        margin-top: 0;
        .wishlist-header-icon {
          // display: none;
        }
      }
    }
  }
}
