@import "./wishlistPage/wishlistPage";

@keyframes pulseTwo {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.7);
  }

  50% {
    transform: scale(1.1);
  }

  75% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
}

svg {
  &.loading {
    animation: pulseTwo 2s infinite;
  }
}

#plp-wishlist-modal {
  order: 3;
  .modal-content {
    max-height: 65rem;
    overflow-y: scroll;
    top: 30px;
  }
}
.product-page-container {
  .modal.show
    .modal-dialog.wishlist-modal
    .modal-content
    .md-form.form-group
    label.project-name {
    left: 2rem !important;
  }
}
.products-list {
  .single-product {
    .modal.show
      .modal-dialog.wishlist-modal
      .modal-content
      .md-form.form-group
      label.project-name {
      left: -4rem !important;
    }
  }
}
.modal {
  &.show {
    .modal-dialog.wishlist-modal {
      .modal-content {
        background-color: #ffffff;
        max-width: 55rem;
        margin: 0 auto;
        img {
          max-width: 100%;
          height: auto;
          max-height: 150px;
        }

        .md-form.form-group {
          input {
            padding: 1.5rem 3rem !important;
            font-size: 1.4rem;
            letter-spacing: 0.88px;
            line-height: 1.9rem;
            border: 1px solid #979797 !important;
            margin-top: 41px;
            &:focus {
              box-shadow: none;
            }
          }
          label {
            -webkit-transform: translateY(-25px);
            transform: translateY(-25px);
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            background: #fbfcf5 !important;
            left: 2rem !important;
            position: relative;
            top: -4rem;
            text-transform: uppercase;
            background: #fff;
            z-index: 5;
            padding: 0 0.5rem;
            font-size: 1.2rem;
            letter-spacing: 0.75px;
            font-weight: 500;
            line-height: 1.9rem;
            &.enter-email,
            &.password-label {
              top: -52px;
            }
            &.enter-email {
              left: -150px !important;
            }
            &.password-label {
              left: -91px !important;
            }
          }
        }
        .modal-header {
          color: #fff;
          padding: 2rem;
          width: 100%;
          display: flex;
          justify-content: center;
          border-bottom: 0;
          background-image: linear-gradient(to bottom, #151521, #211f44);
          .modal-title {
            padding-top: 0px;
          }
          p {
            margin: 0 auto;
            margin-top: 0;
          }
          svg > g {
            stroke: white !important;
          }
        }
        // border-top: 0.3rem solid $blue;
        .close-btn {
          position: absolute;
          right: 0;
          color: white;
          cursor: pointer;
          top: 0;
        }
        .close-btn,
        button {
          &:focus {
            outline: none;
          }
        }
        .modal-body {
          padding-bottom: 7.5rem;
          padding-left: 25px;
          padding-right: 25px;
          .container {
            display: block;
          }
          div:nth-of-type(2) {
            .choose-project {
              border-top: 0.1rem solid #ccc !important;
            }
          }

          .action-content {
            .link-elaine {
              span {
                // color: #979797;
                font-weight: bold;
              }
            }
          }
          .modal-title-wrapper {
            margin-bottom: 0rem;
          }
          button {
            margin-bottom: 2.3rem;
            span {
              color: #a6c;
            }
            &.btn.white-button {
              background: transparent !important;
              border: none !important;
              // border-bottom: 0.1rem solid #ccc !important;
              padding: 3.5rem 0 2.5rem 0 !important;
              &:focus {
                box-shadow: none;
              }
              svg {
                margin-right: 1.5rem;
              }
            }
          }
          h5 {
            line-height: 3rem;
            letter-spacing: 0.1rem;
            color: black;
            font-size: 1.5rem;
          }
          h3 {
            margin-bottom: 2.3rem;
          }
          p {
            font-size: 12px;
            line-height: 1rem;
            letter-spacing: 0.2rem;
            max-width: 100%;
            color: black;
            &.product-price {
              font-size: 14px;
              line-height: 1.3rem;
              letter-spacing: 0.215rem;
            }
            &.enter-password {
              padding: 0 4rem;
              font-size: 14px;
              line-height: 2.4rem;
              letter-spacing: 0.1rem;
            }
          }
          a {
            padding: 0.4rem 0rem;
            margin: 0 1rem;
            font-size: 10px;
            letter-spacing: 0.167rem;
            line-height: 1.6rem;
            display: inline-flex;
            // color: #979797;
            &:after {
              background: #979797;
            }
          }
          .link-elaine-favorites {
            span {
              text-transform: uppercase;
              position: relative;
              overflow: visible;
              font-weight: bold;
              // @extend %sans-serif-flf-demibold;
              padding: 0.4rem 0rem;
              margin: 0 1rem;
              font-size: 10px;
              letter-spacing: 0.167rem;
              line-height: 1.6rem;
              display: inline-flex;
              // color: #979797;
              &:after {
                content: "";
                width: 100%;
                height: 0.1rem;
                background: #979797;
                position: absolute;
                bottom: 0;
                left: 0;
                transition: all 0.5s ease-in-out;
              }
              &:hover {
                &:after {
                  width: 110%;
                  left: -5%;
                  background: #979797;
                  transition: all 0.5s ease-in-out;
                }
              }
            }
            &.sign-in {
              span {
                &::after {
                  display: none;
                }
              }
            }
          }
          .default-input-field-container {
            input {
              margin-bottom: 2rem;
            }
            margin-bottom: 2rem;
          }
          .wrong-email {
            margin: 0rem 0 1rem 0;
          }
          .choose-project {
            border-color: #ccc !important;
            .project-name {
              color: #979797;
              font-size: 24px;
              line-height: 3.2rem;
              letter-spacing: 0.1rem;
            }
            .number-of-items {
              color: #979797;
              line-height: 2.4rem;
              letter-spacing: 0.1rem;
            }
            .check-wishlist-wrapper {
              > div {
                display: flex;
                align-items: center;
                .checkbox-container {
                  margin-bottom: 0;
                }
              }
            }
          }
          .add-project-name {
            button {
              padding: 1.5rem !important;
              min-width: 100%;
            }
          }
        }
      }
    }
  }
}
/*Media Queries*/
@media screen and (max-width: $screen__l) {
  #plp-wishlist-modal {
    &.second-modal {
      .modal-content {
        max-height: 57rem;
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .modal.show {
    .modal-dialog.wishlist-modal.modal-wrapper {
      .modal-content {
        .md-form.form-group {
          label {
            &.enter-email {
              left: -30% !important;
              top: -55px;
            }
          }
        }
      }
    }
  }
}
