.breadcrumbs-title {
  > div {
    display: flex;
    align-items: center;
    font-family: Montserrat;
    a,
    span {
      font-size: inherit;
      display: inline-block;
      font-family: Montserrat;
      color: #212529;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    span:not(:first-child) {
      padding: 0 0.5rem;
    }
    a {
      padding: 0;
      &:hover {
        span {
          color: #000000;
        }
      }
      span {
        border-right: 0.1rem solid #212529;
        &:after,
        &:before {
          content: "";
          background-color: #fff;
          position: absolute;
          right: 0;
          width: 1rem;
          height: 0.6rem;
        }
        &:after {
          top: 0;
        }
        &:before {
          bottom: 0;
        }
      }
    }
  }
}
