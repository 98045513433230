footer {
  color: $black !important;
  font-size: 1.4rem;
  line-height: 2.7;
  letter-spacing: 0.88px;
  padding: 9rem 0 0rem;
  border-top: $lightGrayBorder;
  .left-part {
    a {
      font-weight: 400;
    }
    .logo {
      display: flex;
      max-height: 8rem;
      align-items: center;
      justify-content: flex-start;
      margin: 0 0 2.5rem;
    }

    .arkansas-logo {
      margin: 0 0 1.1rem;
    }
  }
  .right-part {
    padding-top: 8.5rem;
    @media screen and(max-width:1200px) {
      padding-top: 1rem;
    }
    a {
      @media screen and (max-width: 1366px) {
        font-size: 1.2rem;
      }
      font-weight: 500;
      text-transform: uppercase;
    }
  }
  a {
    color: $black !important;
    text-transform: initial;
    font-size: 1.4rem;
    line-height: 2.7;
    letter-spacing: 0.88px;
  }
  .nav-link {
    padding: 0;
  }
}

.footer-column {
  flex-direction: column;
}

@media (max-width: 767px) {
  footer {
    padding: 2rem 1.5rem;
    .left-part {
      border-top: $lightGrayBorder;

      a {
        font-size: 1.2rem;
      }

      .footer-column {
        flex-direction: row;
        padding: 1.5rem 1rem;

        .logo {
          margin-right: 2rem;
        }

        &:first-child {
          .logo {
            content: url(../../assets/images/logo-mobile.svg);
            width: 4.3rem;
            height: 6.3rem;
          }
        }
        &:nth-child(2) {
          .logo {
            content: url(../../assets/images/arkansas-logo.png);
            width: 4.7rem;
            height: 4.7rem;
          }
        }
      }
    }
    .right-part {
      padding-top: 0;
      > div {
        border-top: $lightGrayBorder;
        padding: 1.5rem 1rem;
        a {
          font-weight: 600;
        }
        &:last-child {
          border-bottom: $lightGrayBorder;
        }
      }
    }
  }
  .footer-column {
    .arkansas-info,
    .prima-info {
      p {
        font-size: 1.2rem;
      }
    }
  }
}

footer {
  .modal.show {
    svg  g{
      stroke:#ffffff;
    }
    .modal-dialog {
     position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width:683px;
      //margin:15px

    }
    .loginModal {

      background: #2F3181;
      color:#ffffff;
      min-height: 600px;
    }
    .popupModalInerWrap {

      width: 511px;

      top: 50%;
     left: 50%; // half of height
      transform: translate(-50%,-50%) !important;
      position: absolute;
      h2 {
        font-size: 3rem;
      }
      p {
        color:white;
        font-size: 1.5rem;
        font-family: SangBleuSunriseRegular !important;
      }
    }
    @media screen and (max-width: 830px) {
      .popupModalInerWrap {
        width: 80%;
      }
      .modal-dialog {
        width: 80%;
      }

      h2 {
        font-size: 3rem;
        line-height: 31px!important;
        //font-size: 30px;
        margin-bottom: 43px!important;
        padding-top: 65px!important;
      }
    }
}
}