.root {
  margin-top: 10.4rem;
}

/* ---- general settings ----- */
.root :global .pagebuilder-mobile-only {
  display: none;
}

.root img {
  width: auto;
  height: 43rem;
}

.root :global .category-col figure > a {
  padding: 0;
}

.root :global .pagebuilder-column-line {
  gap: 2rem;
}

.root figure {
  margin-bottom: 0rem;
}

.root :global .pagebuilder-column-group {
  margin-top: 6rem;
}

.root :global .pagebuilder-column-group,
.root :global .pagebuilder-column-line {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
/* ---- general settings ----- */

.root :global .hero-header-row img {
  width: 100%;
  height: 66rem;
}

.root :global .hero-header-row .pagebuilder-column {
  width: 100%;
}

.root :global .hero-header-row .text-absolute {
  position: absolute;
  top: 0px;
  margin-top: 10.4rem;
  height: 66rem;
  width: 73rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.root :global .hero-header-text {
  padding: 9.2rem 8rem;
  margin-bottom: 4rem;
  background-color: #fbfcf5;
  opacity: 0.7;
}

.root :global .hero-header-title {
  font-size: 3.2rem;
  font-family: "SangBleu Sunrise" !important;
  font-weight: 400;
  padding-bottom: 2.2rem;
  color: black;
  letter-spacing: 0.1rem;
}

.root :global .hero-header-desc {
  font-size: 1.8rem;
  color: #211f44;
  line-height: normal;
}

.root :global .item-label {
  font-size: 2.4rem;
  font-family: "SangBleu Sunrise" !important;
  font-weight: 400;
  padding-top: 1rem;
}

.root :global .item-description {
  font-size: 1.6rem;
  padding: 1rem 0rem;
  max-width: 55rem;
}

.root :global .see-collection {
  font-size: 1.6rem;
  font-weight: bold;
  text-transform: uppercase;
}

.root :global .other-lighted-mirrors {
  position: relative;
}

.root :global .other-container {
  background-color: rgba(21, 21, 42, 0.4);
  display: flex;
  flex-direction: column;
  height: calc(100% - 12.4rem);
  width: 100%;
  justify-content: center;
  padding: 4rem;
  position: absolute;
  top: 0;
  left: 0;
}

.root :global .other-title {
  font-size: 4.2rem;
  font-family: "SangBleu Sunrise" !important;
  color: white;
  line-height: 58px;
}

.root :global .other-explore {
  width: fit-content;
  color: white;
  font-size: 1.4rem;
  padding: 1.7rem 5rem;
  margin-top: 4rem;
  border: 1px solid white;
  text-transform: uppercase;
  min-width: 6rem;
}

/* ---- more section ---- */
.root :global .more-section {
  background-color: #fbfcf5;
  padding: 8rem 8.2rem;
  margin-top: 13rem;
}

.root :global .more-title {
  font-size: 2.8rem !important;
  font-family: "SangBleu Sunrise" !important;
  padding-bottom: 2rem;
}

.root :global .more-left {
  margin-right: 12.8rem;
  width: 100%;
}

.root :global .more-left .divider {
  margin: 4rem 0rem;
}

.root :global .more-section-two p {
  font-size: 1.6rem;
}

.root :global .divider {
  height: 1px;
  width: 100%;
  background-color: #979797;
  margin: 1.8rem 0rem;
}

.root ul {
  font-size: 1.6rem;
  font-family: "Montserrat";
  line-height: 2.4rem;
}

.root li {
  padding-bottom: 0.8rem;
}

.root :global .more-right {
  width: 100%;
}

.root :global .features-container {
  font-size: 1.8rem;
  font-weight: 500;
  font-family: "Montserrat";
  text-transform: uppercase;
}
/* ---- more section ---- */

@media screen and (max-width: 1200px) {
  .root :global .category-col {
    width: 47vw;
  }
  .root :global .hero-header-row img,
  .root :global .hero-header-row .text-absolute {
    height: 50rem;
  }
}

@media screen and (max-width: 1024px) {
  .root {
    margin-top: 10rem;
  }

  .root :global .category-col {
    width: 47vw;
  }
}

@media screen and (max-width: 1039px) and (min-width: 801px) {
  .root :global .other-container {
    height: calc(100% - 15.2rem);
  }
}

@media (min-width: 801px) and (max-width: 991px) {
  .root {
    margin-top: 6.4rem;
  }

  .root :global .category-image img {
    width: 100%;
    height: 30rem;
  }

  .root :global .hero-header-text {
    padding: 6rem 5rem;
  }
}

@media screen and (max-width: 800px) {
  .root :global .pagebuilder-column-line {
    flex-direction: column;
  }
  .root :global .pagebuilder-column {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .root :global .other-container {
    height: 100%;
  }

  .root :global .pagebuilder-column:first-child {
    margin-bottom: 5rem;
  }

  .root img {
    width: 70vw;
  }

  .root {
    margin-top: 7rem;
  }

  .root :global .hero-header-row img {
    width: 100%;
    height: 50vw;
  }

  .root :global .hero-header-row .text-absolute {
    position: relative;
    height: fit-content;
    margin-top: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .root :global .hero-header-text {
    padding: 7rem 8rem 0rem 8rem;
    margin-bottom: 0rem;
    background-color: white;
  }

  .root :global .column-width {
    width: 100%;
  }

  .root :global .category-col,
  .root :global .pagebuilder-column > div,
  .root :global .pagebuilder-column > figure {
    width: fit-content;
    align-self: center;
  }

  .root :global .category-image img {
    height: auto;
  }
}

@media screen and (max-width: 576px) {
  .root :global .other-container {
    padding: 1.5rem;
  }
  .root :global .other-title {
    font-size: 2.6rem;
    line-height: normal;
  }
}
