@import "../../style/_typography";

.login-modal-popup-wrapper {
  .modal::-webkit-scrollbar {
    display: none;
  }

  .modal {
    .modal-dialog {
      max-width: 117rem;
    }
    --scrollbarBG: transparent;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
    --thumbBG: transparent;
    overflow-y: scroll !important;
    display: flex;
    justify-content: center;
  }
}

.loginModal {
  .flex-column {
    background-color: #ffffff;
  }
  .container {
    max-width: unset;
  }
  background-color: #ffffff;
  width: 100%;
  min-height: 535px;
  border: solid 1px #e1e1e1;
  padding: 0px;
  margin: 0px;
  .terms-and-conditions-link {
    span {
      opacity: 0.8;
      text-decoration: underline;
      &:hover {
        opacity: 1;
      }
    }
  }
  .login-tab-wrapper {
    margin-left: 8.3rem;
    margin-right: 8.3rem;
    @media screen and(max-width: 1200px) {
      margin-left: 0rem;
      margin-right: 0rem;
    }
    .btn.btn-lg {
      margin-bottom: 6% !important;
    }
    @media screen and(max-width: 768px) {
      .btn.btn-lg {
        margin-bottom: 2% !important;
      }
    }
    .login-tab-right-wrapper {
      @media screen and(max-width: 768px) {
        padding-bottom: 3rem;
        p {
          font-size: 1.4rem !important;
          line-height: 2rem;
        }
        a {
          font-size: 1.4rem !important;
        }
      }
      margin-top: 5rem;
      p {
        font-size: 1.6rem;
        font-family: Montserrat !important;
        padding-bottom: 1.5rem;
      }

      a {
        padding-top: 3rem !important;
        font-weight: 700;
        text-decoration: underline;
        font-family: Montserrat;
        font-size: 1.6rem;
        letter-spacing: 1px;
        text-align: center;
        color: #151522;
        text-transform: uppercase;
        &:hover {
          font-size: 1.7rem;
        }
      }
    }
  }
  .tabsLabel {
    position: relative;
    align-items: center;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 8rem;
    letter-spacing: 1.44px;
    text-align: center;
    color: #15152a;
    border: none !important;
    background: transparent !important;
  }
  form {
    .row {
      .col {
        width: 100%;
        input {
          width: 100%;
        }
      }
    }
  }
}

.tabs {
  width: 50%;
  height: 90px;
  border: solid 1px #e1e1e1;
  background-color: $white;
  border-left: none;
}

.activeTab {
  width: 50%;
  height: 90px;
  border-right: solid 1px #e1e1e1;
  border-top: solid 3px $orange;
  background-color: #ffffff;
  border-bottom: none;
  border-left: none;
}

.loginModal .nav-tabs {
  margin-top: -1px !important;
}

.input-login-label {
  background: $white !important;
  left: 7rem !important;
}

.btn-lg {
  width: 472px !important;
}

.paragraph-small {
  text-align: right !important;
  margin-top: 20px;
  margin-right: 20px;
}

.forgot-password-link {
  margin-right: 20px;
  font-weight: normal;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.75px;
  text-align: left;
  color: #000000;
}

.error-label {
  color: #f83535 !important;
}

.register {
  @media screen and(max-width: 600px) {
    .form-check {
      padding-left: 0;
    }
    .col {
      padding-left: 0;
      padding-right: 0;
    }
  }
  margin-left: 12.3rem;
  margin-right: 12.3rem;
  @media screen and(max-width: 1200px) {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }

  padding-bottom: 5rem;
  .select2-container {
    // padding: 0 1.9rem;
    margin-bottom: 3rem;
    .select2 {
      border: solid 1px #979797 !important;
      border-radius: 0;
      &:focus {
        outline: none;
        box-shadow: 0 1px 0 0 #4285f4;
      }
      .react-select__menu-list {
        div {
          font-family: SangBleuSunrise !important;
        }
        .react-select__value-container--has-value {
          .react-select__single-value {
            div > div {
              font-family: SangBleuSunriseRegular !important;
            }
          }
        }
        background-color: #ffffff;
        font-family: SangBleuSunrise !important;
        letter-spacing: 0.1rem;
        .react-select__option {
          font-family: SangBleuSunrise !important;
        }
        // react-select__option--is-selected
      }
      .react-select-container {
        .react-select__placeholder {
          font-family: SangBleuSunrise !important;
          padding-left: 0;
        }
        &:focus {
          outline: none;
        }
      }
      .react-select__control {
        border-radius: 0;
        border: none;
        padding: 0.2rem 0;
        &.react-select__control--is-focused {
          box-shadow: none;
        }
      }
    }
    .select2-label {
      background-color: #fbfcf5;
      left: 4.5rem;
      top: -0.3rem;
      font-size: 14px !important;
      // letter-spacing: 0.75px;
      font-weight: 400;
      color: #000000;
    }
    .react-select__value-container {
      padding-left: 2.7rem;
      font-family: SangBleuSunrise;
    }
    .react-select__single-value {
      padding-left: 0 !important;
      font-size: 1.4rem;
      margin: 0;
      font-family: SangBleuSunrise;
      color: #495057;
    }
  }
  .register-label {
    position: absolute;
    font-family: Montserrat;
    font-size: 12px !important;
    letter-spacing: 0.75px;
    color: #000000;
    background-color: #fbfcf5;
    left: 2.5rem;
    top: -1rem;
    padding-right: 5px;
    padding-left: 5px;
  }

  .register-error-label {
    color: #f83535 !important;
  }

  .register-input {
    font-family: SangBleuSunrise;
    font-size: 1.4rem;
    // margin: auto;
    // width: 472px !important;
    @media screen and (max-width: 400px) {
      width: 31rem !important;
    }
    height: 50px;
    border: solid 1px #979797 !important;
    padding-left: 30px !important;
    margin-bottom: 30px;
  }

  .register-error-input {
    border: solid 1px #f83535 !important;
  }

  .select-wrapper {
    // width: 472px !important;
    margin: auto !important;
    margin-bottom: 30px !important;
  }

  .register-error-msg {
    color: #f83535;
    font-size: 1rem;
    margin-bottom: 20px;
    margin-top: -25px;
    margin-left: 40px;
  }

  .mdb-main-label {
    background: #fbfcf5 !important;
  }
}
