.product-item-container {
  display: flex;
  flex-direction: column;
  padding: 1;
  div {
    max-width: 100%;
    margin: 0;
  }
  .product-item-header-row {
    height: 5rem;
    display: flex;

    .wishlist-product-widget {
      display: flex;
      justify-content: flex-end;
      svg {
        cursor: pointer;
        height: 2.5rem;
        width: 2.5rem;
        path {
          transition: all 0.5s ease-in-out;
        }
      }
      svg:hover {
        path {
          transition: all 0.5s ease-in-out;
        }
      }
    }
    .trash-can-wrapper {
      margin-top: 1rem;
      margin-right: 1rem;
      svg {
        cursor: pointer;
      }
      svg path {
        transition: all 0.5s ease-in-out;
      }
      svg:hover {
        transition: all 0.5s ease-in-out;
      }
    }
  }
  .product-item-image-row {
    z-index: 1;
    display: flex;
    justify-content: center;
    cursor: pointer;
    text-align: center;
    div {
      padding: 0 1rem;
    }
    .product-item-image-column {
      height: 14.5rem;
      width: 13.8rem;
      img {
        height: 100%;
        width: 100%;
        object-fit: scale-down;
        transform: scale(1);
        transition: all 0.5s ease-in-out;
        &.placeholder {
          padding: 6rem;
        }
      }
    }
    p {
      margin-top: 1rem;
      // font-size: $f14;
      line-height: 2.6rem;
    }
  }
  .product-item-info-row {
    height: 10rem;
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    div {
      padding: 0 1rem;
    }
    h5 {
      line-height: 2.5rem;
    }
  }
}
.product-item-container:hover {
  .product-item-image-row .product-item-image-column img {
    transform: scale(1.1);
  }
}

@media screen and(min-width:2000px) {
  .product-item-container .product-item-image-row .product-item-image-column {
    max-height: 40rem;
  }
}

@media screen and(max-width:$screen__l) {
  .product-item-container {
    .product-item-image-row {
      .product-item-info {
        max-width: 50%;
      }
      p {
        text-align: center !important;
        line-height: 1.8rem;
        margin-top: 0;
        margin-bottom: 1rem;
      }
    }
    .product-item-info-row {
      height: 9rem;
      h5 {
        line-height: 2rem;
      }
    }
  }
}
@media screen and(max-width:600px) {
  .product-item-container {
    .product-item-image-row {
      .product-item-image-column {
        height: 12.5rem;
        width: 12.5rem;
        padding: 0 1rem;
      }
      p {
        text-align: left;
        line-height: 1.8rem;
        margin-top: 1.5rem;
      }
    }
    .product-item-header-row {
      height: 3rem;
      .product-new-label {
        svg {
          height: 3.2rem;
          width: 3rem;
        }
      }
      .wishlist-product-widget {
        svg {
          height: 1.6rem;
          width: 1.6rem;
        }
      }
      .trash-can-wrapper {
        svg {
          height: 1.6rem;
          width: 1.6rem;
        }
      }
    }
    .product-item-info-row {
      height: 12rem;
      h5 {
        line-height: 2rem;
      }
    }
  }
}

@media screen and(max-width:320px) {
  .product-item-container {
    .product-item-image-row {
      .product-item-image-column {
        height: 10.5rem;
        width: 10.5rem;
      }
    }
    .product-item-info-row {
      height: 12rem;
    }
  }
}
