.thank-you-quote-request-wrapper {
  margin-bottom: 20rem;
  .main-column {
    margin: 2%;
    margin-top: 19rem !important;
    align-items: center;
    position: relative;
    @media (max-width: 991.98px) {
      margin-top: 3rem !important;
    }
    img {
      align-items: center;
      width: 100%;
      height: 60rem;
      @media screen and (max-width: 600px) {
        height: 70rem;
      }
      overflow: hidden;
      position: absolute;
      z-index: -100;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    h1 {
      margin-top: 17rem;
      p {
        font-size: 4.2rem;
        letter-spacing: 1px;
        text-align: center;
        color: #ffffff !important;
        font-family: SangBleuSunriseRegular !important;
        font-weight: 300 !important;
        line-height: 1.38;
        @media screen and(max-width: 768px) {
          margin-top: 5rem !important;
        }
      }
    }

    h3 {
      p {
        font-family: Montserrat !important;
        font-size: 1.8rem;
        font-weight: normal;
        color: #ffffff;
      }
    }
  }
  .continue-button {
    color: #ffffff !important;
    border: 2px solid #f29823;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 6rem;
    a {
      font-family: Montserrat !important;
    }

    &:hover {
      p {
        a {
          color: #f29823;
        }
      }
    }
    p {
      a {
        width: 30rem;
        letter-spacing: 1px;
        font-weight: bold;
        font-size: 14px;
        color: #ffffff;
        line-height: 1.8rem;
      }
    }
  }
}
/*Media Queries*/
@media (max-width: 575.98px) {
  .thank-you-quote-request-wrapper {
    margin-bottom: 14rem;
    .main-column {
      h1 {
        p {
          font-size: 2.8rem;
        }
      }
      .continue-button {
        p {
          a {
            text-transform: uppercase;
          }
        }
      }
      .home-btn {
        a {
          font-size: 1.4rem;
        }
      }
    }
  }
}
