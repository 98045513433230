



@media print {

  @page {
    size:auto;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .footerForPdf {bottom:0cm;}



}



