.parameters-section {
  .md-accordion {
    .parameters-section-wrapper-mobile {
      .card {
        transition: all 0.2s ease-in-out;
        background-color: #fbfcf5;
        .card-header {
          border-bottom: none !important;
          padding: 0 !important ;
          h5 {
            transition: all 0.2s ease-in-out;
            font-size: 1.8rem;
            display: flex;
            justify-content: space-between;
            color: #212529 !important;
            max-width: calc(100% - 41px);
            margin: 0 auto;
            padding: 2.4rem 0;
          }
        }
        .parameters-sub-category {
          max-width: calc(100% - 41px);
          margin: 0 auto;
          .card-header {
            h5 {
              font-size: 1.6rem;
            }
          }
          .card-body {
            margin: 0 auto;
            max-width: calc(100% - 41px);
          }
        }
      }
    }
  }
}
