.catalog-section {
  padding-top: 18rem;
  .catalog-container {
    margin: auto;
    width: 115rem;
    padding: 3.5rem;
    padding-bottom: 0;
    max-width: 100%;
    background-size: cover !important;
    .h5 {
      p {
        text-align: center;
        font-family: SangBleuSunriseRegular !important;
        font-size: 2.8rem;
        color: #ffffff;
        @media screen and(max-width: 1000px) {
          font-size: 2rem;
        }
      }
      color: #fff;
      margin-bottom: 5rem;
    }
    .btn {
      font-size: 1.4rem;
      font-weight: 700;
    }
  }
  .flex-column {
    background: transparent;
  }

  img {
    width: 100%;
  }
}

@media (min-width: 1981px) {
  .catalog-section {
    .catalog-container {
      width: 100%;
      padding: 0;
    }
  }
}

@media (max-width: 767px) {
  .catalog-section {
    padding-top: 0;
    .container {
      padding: 0;
      .catalog-container {
        padding: 5rem 2.5rem 4rem;
      }
      .h5 {
        font-size: 1.8rem;
        font-weight: 300;
        margin-bottom: 4rem;
        margin-top: 1rem;
      }
      .btn {
        width: 100%;
        font-size: 1.6rem;
      }
    }
  }
}
