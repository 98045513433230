#search-container {
  #search-input-wrapper {
    .input-filed-column {
      > div {
        border-bottom: solid 1px #15152a;
        input {
          &.form-control {
            font-size: 1.6rem;
            &:focus {
              box-shadow: none;
            }
          }
        }
      }
    }
    width: 87.6rem;
    border: none;
    .close-btn-mobile {
      display: none;
    }
    svg {
      margin-right: 2rem;
    }
    #search-input:active,
    #search-input:focus,
    #search-input:visited {
      outline: none !important;
      border: none !important;
    }
    button,
    input {
      outline: none !important;
      border: none !important;
    }
  }
}

@media screen and(max-width: 800px) {
  #search-container {
    #search-input-wrapper {
      width: 57rem;
      border: none;
    }
  }
}

@media (max-width: 575.98px) {
  #search-container {
    #search-input-wrapper {
      width: 100%;
      border: none;
    }
  }
}
