// colors
$black: #15152a;
$blue: #2f3181;
$white: #fbfcf5;
$orange: #f29823;

// gradients
$blueDark: #151521;
$blueLight: #211f44;
$blueList: $blueDark, $blueLight;
$orangeDark: #f29822;
$orangeLight: #efb950;
$orangeList: $orangeDark, $orangeLight;
$grayDark: rgba(162, 165, 190, 0.5);
$grayLight: rgba(221, 225, 242, 0.5);
$grayList: $grayLight, $grayDark;

// family SangBleuBPtestFontSans
$fontMonserat: Montserrat;
$fontFamily: SangBleuSunriseRegular, "Segoe UI", Tahoma, Geneva, Verdana,
  sans-serif;

@mixin gradient($direction, $list) {
  background: -webkit-linear-gradient($direction, $list) !important;
  background: -moz-linear-gradient($direction, $list) !important;
  background: -o-linear-gradient($direction, $list) !important;
  background: linear-gradient($direction, $list) !important;
}

// borders
$darkGrayBorder: 1px solid #979797;
$lightGrayBorder: 1px solid #e1e1e1;
$orangeBorder: 3px solid #f29823;
$blueBorder: 3px solid #15152a;

body {
  font-family: $fontFamily;
}
// buttons
.btn {
  margin: 0;

  &.btn-lg {
    padding: 1.8rem;
    font-size: 1.6rem;
    width: 50.8rem;
    font-family: $fontMonserat;
  }

  &.btn-sm {
    padding: 1.6rem;
    font-size: 1.2rem;
    width: 15.5rem;
    font-family: $fontMonserat;
  }
}

.btn-default {
  background: $orange !important;
  color: #fff;
  padding-top: 2.1rem !important;
  padding-bottom: 2rem !important;
  width: 29.6rem;
  font-size: 1.6rem;
  text-align: center;
  font-weight: bold;
  font-family: $fontMonserat;
  margin: 0;
  line-height: 1.9rem;
  letter-spacing: 1px;
  box-shadow: 0 0;

  // &:hover {
  //   @include gradient(to right, $orangeList);
  //   box-shadow: 0 0;
  // }

  &:active {
    @include gradient(to right, $orangeList);
    box-shadow: 0 0 !important;
  }

  &:focus {
    @include gradient(to right, $orangeList);
    box-shadow: 0 0;
  }
}

// large buttons
.btn-lg {
  font-size: 1.4rem !important;
  padding-top: 2.1rem !important;
  padding-bottom: 1.9rem !important;
  width: 50.6rem;
  line-height: 2.1rem;
  font-family: $fontMonserat;
}

// small buttons
.btn-sm {
  padding-top: 1.7rem !important;
  padding-bottom: 1.7rem !important;
  font-size: 1.2rem !important;
  width: 15.5rem;
  line-height: 1.65rem;
  font-family: $fontMonserat;
}

.btn-outline-default {
  color: $black !important;
  border: 1px solid $black !important;
  font-size: 1.6rem;
  font-family: $fontMonserat;
  padding: 2rem;
  width: 29.6rem;
  letter-spacing: 1px;
  box-shadow: 0 0;

  &:hover {
    color: $black !important;
    border: 1px solid $black !important;
    @include gradient(to right, $grayList);
    box-shadow: 0 0;
  }

  &:focus {
    color: $black !important;
    border: 1px solid $black !important;
    @include gradient(to right, $grayList);
    box-shadow: 0 0;
  }

  &:not([disabled]) {
    &:not(.disabled) {
      &:active {
        color: $black !important;
        border: 1px solid $black !important;
        @include gradient(to right, $grayList);
        box-shadow: 0 0;

        &:focus {
          box-shadow: 0 0;
        }
      }
    }
  }
}

.blue-back {
  background: #2f3181;
}

.btn-outline-default.outlined-white {
  color: #fff !important;
  border: 1px solid #fff !important;
  background: #2f3181;
  width: 27rem;
  height: 70px;
  font-family: $fontMonserat;
}

.btn-outline-default.outlined-white {
  color: #fff !important;
  border: 1px solid #fff !important;
  width: 25rem !important;
  padding-top: 2.1rem !important;
  padding-bottom: 2.1rem !important;
  line-height: 1.65rem;
  font-family: $fontMonserat;
}

// outlined button large
.btn[class*="btn-outline-"].btn-lg {
  padding-top: 2.1rem !important;
  padding-bottom: 2rem !important;
  width: 50.8rem;
  font-size: 1.6rem !important;
  font-family: $fontMonserat;
  line-height: 1.8rem;
}

.btn.btn-sm.btn-outline-default {
  padding: 1.5rem;
}

// outlined button small
.btn[class*="btn-outline-"].btn-sm {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.btn[class*="btn-outline-"] {
  padding-top: 1.7rem;
  padding-bottom: 1.7rem;
  padding-top: 1.8rem !important;
  padding-bottom: 1.7rem !important;
  width: 15.5rem;
  font-size: 1.2rem !important;
  line-height: 1.4rem;
}

.btn[class*="btn-outline-"] {
  padding-top: 1.7rem;
  padding-bottom: 1.7rem;
  width: 29.6rem;
  margin: 0 !important;
  font-weight: 500 !important;
}

// create new button
.btn-create-new {
  padding: 0 !important;
  background: #fff !important;
  color: $black;
  font-size: 1.4rem;
  letter-spacing: 1.4px;
  font-weight: 500;
  width: auto;
  box-shadow: 0 0 !important;
  font-family: $fontMonserat;

  &:hover {
    background: #fff !important;
    color: $black;
  }

  i {
    margin-right: 12px;
    padding: 1.5rem;
    border-radius: 50%;
    background: $orange;
    color: #fff;
  }
}

// Headings
.display-1 {
  font-size: 6.8rem;
  font-weight: 300;
  color: $black;
  line-height: 1.18;
  font-family: $fontFamily;
}

.h1 {
  font-size: 4.2rem;
  font-weight: 300;
  line-height: 1.38;
  color: #fff;
  letter-spacing: 2.6px;
  margin-bottom: 0;
  font-family: $fontFamily !important;
}

.h2 {
  font-size: 4rem;
  font-weight: 400;
  line-height: 1.25;
  color: $black;
  letter-spacing: 1.9px;
  margin-bottom: 0;
  font-family: $fontFamily !important;
}

.h3 {
  font-size: 3.6rem;
  font-weight: 300;
  line-height: 1.28;
  color: $black;
  letter-spacing: 2.25px;
  margin-bottom: 0;
  font-family: $fontFamily !important;
}

.h4 {
  font-size: 3.2rem;
  font-weight: 400;
  line-height: 1.3;
  color: $black;
  letter-spacing: 2px;
  margin-bottom: 0;
  font-family: $fontFamily !important;
}

.h5 {
  font-size: 2.8rem;
  font-weight: 400;
  line-height: 1.36;
  color: $black;
  letter-spacing: 1px;
  margin-bottom: 0;
  font-family: $fontFamily !important;
}

.h6 {
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 1.42;
  color: $black;
  letter-spacing: 1.5px;
  margin-bottom: 0;
  font-family: $fontFamily !important;
}

p.paragraph-small {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.4rem;
  color: #595959;
  letter-spacing: 0.78px;
}

p {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.8rem;
  color: #595959;
  letter-spacing: 1px;
  margin-bottom: 0;
  font-family: $fontMonserat !important;
}

label {
  font-size: 2rem;
  letter-spacing: 1.25px;
  color: $black;
  font-weight: 400;
  line-height: 3.4rem;
  margin-bottom: 0;
  font-family: $fontFamily !important;
}

input[type="text"]::placeholder {
  color: $black;
  font-size: 1.4rem;
  font-weight: 300;
  font-family: $fontFamily;
}

.md-form {
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  label {
    font-size: 1.4rem !important;
    font-weight: 500;
    color: $black !important;
    letter-spacing: 0.88px;
    line-height: 2.4rem;
    text-transform: uppercase;
  }
}

label.some-label {
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.4rem;
  color: $black;
  letter-spacing: 1.25px;
  text-align: center;
}

label.price-label {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.6;
  color: $black;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  font-family: $fontMonserat;
}

a {
  font-size: 1.6rem;
  color: $black;
  line-height: 2.4rem;
  letter-spacing: 1px;
  font-family: $fontMonserat;
}

a.small {
  font-size: 1.4rem;
  color: $black;
  line-height: 2.4rem;
  letter-spacing: 0.88px;
  font-family: $fontMonserat;
}

span.edit {
  font-size: 1.2rem;
  font-weight: 400;
  color: $black;
  text-transform: uppercase;
  line-height: 2.4rem;
  padding-bottom: 3px;
  border-bottom: 1px solid $black;
  letter-spacing: 1.23px;
  font-family: $fontMonserat;
}

.red-text {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 2.4rem;
  color: #f83535 !important;
  letter-spacing: 0.67px;
}

.form-check-input[type="radio"] {
  &:not(:checked) + label {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 2.4rem;
    color: #1f1e3f;
    text-transform: uppercase;
    letter-spacing: 0.75px;
    font-family: $fontMonserat;
  }

  &:checked + label {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 2.4rem;
    color: #1f1e3f;
    text-transform: uppercase;
    letter-spacing: 0.75px;
    font-family: $fontMonserat;
  }
}

label.btn {
  input[type="radio"] {
    &:not(:checked) + label {
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 2;
      color: #1f1e3f;
      text-transform: uppercase;
      letter-spacing: 0.75px;
      font-family: $fontMonserat;
    }

    &:checked + label {
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 2;
      color: #1f1e3f;
      text-transform: uppercase;
      letter-spacing: 0.75px;
      font-family: $fontMonserat;
    }
  }
}

p.very-small {
  font-size: 1rem;
  font-weight: 400;
  line-height: 2rem;
  color: #595959;
  text-transform: uppercase;
  letter-spacing: 0.63px;
  font-family: $fontMonserat;
}

// inputs
.md-form.input-group {
  // width: 52.5rem;
  .form-control {
    padding: 1.5rem 3rem !important;
    font-size: 1.4rem;
    letter-spacing: 0.88px;
    line-height: 1.9rem;
    border: $darkGrayBorder !important;

    &:focus {
      border: 1px solid #353452 !important;
      box-shadow: 0 0 !important;
    }
  }
}

.input-label {
  position: relative;
  top: 1rem;
  left: 2rem;
  text-transform: uppercase;
  background: #fff;
  z-index: 5;
  padding: 0 0.5rem;
  font-size: 1.2rem;
  letter-spacing: 0.75px;
  font-weight: 500;
  line-height: 1.9rem;
  font-family: $fontMonserat;
}

.md-form.input-group {
  .form-control.error-input {
    border: 1px solid #f83535 !important;
    box-shadow: 0 0 !important;
  }
}

.error-col {
  .error-label {
    color: #f83535;
    font-family: $fontMonserat;
  }
}

.select-wrapper {
  input.select-dropdown {
    padding: 1.5rem 3rem !important;
    font-size: 1.4rem;
    letter-spacing: 0.88px;
    border: $darkGrayBorder !important;
    height: auto;
    line-height: 1.3;
    box-sizing: border-box !important;
    font-family: $fontMonserat;

    &:active {
      border: 1px solid #353452 !important;
      box-shadow: 0 0 !important;
    }

    &:focus {
      border: 1px solid #353452 !important;
      box-shadow: 0 0 !important;
    }
  }

  span.caret {
    right: 1.5rem;
    font-size: 1rem;
    top: 55%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
  }

  > label.mdb-main-label {
    top: -0.9rem !important;
    background: #fff;
    left: 2rem !important;
    padding: 0 1rem;
    width: auto;
    font-size: 1.2rem !important;
    letter-spacing: 0.75px;
    font-weight: 500;
    text-transform: uppercase;
    transform: translateY(0) !important;
    -webkit-transform: translateY(0) !important;
    -moz-transform: translateY(0) !important;
    -ms-transform: translateY(0) !important;
    -o-transform: translateY(0) !important;
    font-family: $fontMonserat;
  }

  ul {
    background: #fbfcf4;
    top: 4.9rem !important;

    span {
      padding: 1.7rem 3rem;
      color: #151522;
      font-size: 1.6rem;
      letter-spacing: 1px;
    }
  }
}

.dropdown-content {
  li {
    &:hover {
      background-color: #f6f7ef;
      border-left: 1px solid #211f44;
    }
  }

  li.active {
    background-color: #f6f7ef;
    border-left: 1px solid #211f44;
  }
}

// small dropdown
.select-wrapper.select-small {
  max-width: 27rem;

  input {
    text-align: center;
    font-size: 1.2rem !important;
    line-height: 1.46rem;
  }

  ul span {
    padding-bottom: 0.6rem !important;
    padding-top: 0.7rem !important;
    padding-left: 0.7rem !important;
    font-size: 1.2rem !important;
    letter-spacing: 0.75px;
    text-align: center;

    img {
      float: left;
      height: 3.7rem;
      width: 3.7rem;
    }
  }

  li {
    line-height: 2.4rem;
    padding: 0;
  }

  .span.caret {
    right: 1rem;
  }

  > label.mdb-main-label {
    left: 50% !important;
    transform: translateX(-50%) !important;
    -webkit-transform: translateX(-50%) !important;
    -moz-transform: translateX(-50%) !important;
    -ms-transform: translateX(-50%) !important;
    -o-transform: translateX(-50%) !important;
  }
}

// dropdown regular
.select-wrapper {
  max-width: 52.5rem;

  input {
    font-size: 1.2rem;
    padding: 1.7rem 3rem !important;
    line-height: 1.46rem;
  }

  ul span {
    padding-bottom: 1.2rem !important;
    padding-top: 1.2rem !important;
  }

  li {
    line-height: 2.4rem;
  }

  .span.caret {
    right: 1rem;
  }

  > label.mdb-main-label {
    left: 13% !important;
    transform: translateX(-50%) !important;
    -webkit-transform: translateX(-50%) !important;
    -moz-transform: translateX(-50%) !important;
    -ms-transform: translateX(-50%) !important;
    -o-transform: translateX(-50%) !important;
  }
}

.md-form {
  textarea.md-textarea {
    // width: 52.5rem;
    height: 12rem;
    border: $darkGrayBorder !important;
    padding: 2rem 3rem;
    font-size: 1.4rem;
    letter-spacing: 0.88px;
    resize: none;

    &:focus {
      border: 1px solid #353452 !important;
      box-shadow: 0 0 !important;
    }
  }

  textarea {
    + {
      label {
        font-size: 1.2rem !important;
        top: -0.9rem !important;
        left: 2rem;
        padding: 0 1rem;
        background: #fff;
        letter-spacing: 0.75px;
      }
    }
  }

  label.active {
    transform: translateY(0) scale(1);
  }
}

.form-check-input[type="checkbox"] + label {
  &:before {
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    margin-top: 0;
    width: 2.2rem;
    height: 2.2rem;
    top: 50%;
    transform: translateY(-50%) !important;
    -webkit-transform: translateY(-50%) !important;
    -moz-transform: translateY(-50%) !important;
    -ms-transform: translateY(-50%) !important;
    -o-transform: translateY(-50%) !important;
    border: 1px solid #211f44;
  }

  font-size: 1.2rem;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  color: #1f1e3f;
}

.form-check-input[type="checkbox"] {
  &:checked + label {
    &:before {
      top: 50%;
      left: 0;
      width: 2.2rem;
      height: 2.2rem;
      border: 2px solid $orange;
      background: $orange;
    }

    &:after {
      top: -4px;
      left: 2px;
      width: 7px;
      height: 1.375rem;
      border-top: 2px solid transparent;
      border-right: 2px solid #fff;
      border-bottom: 2px solid #fff;
      border-left: 2px solid transparent;
      transform: rotate(40deg);
      transform-origin: 100% 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }
  }
}

.dropdown.show {
  .dropdown-toggle {
    &::after {
      border-top: 0;
      border-bottom: 0.8rem solid;
      border-right: 0.4rem solid transparent;
      border-left: 0.4rem solid transparent;
    }
  }
}

.btn-secondary.dropdown-toggle {
  background: transparent !important;
  color: $black;
  font-size: 1.6rem;
  text-transform: initial;
  box-shadow: 0 0;
  padding: 0 0 0.5rem;
  margin: 0;

  &:hover {
    background: transparent !important;
    color: $black;
    box-shadow: 0 0 !important;
  }

  &:focus {
    background: transparent !important;
    color: $black;
    box-shadow: 0 0 !important;
  }
}

.dropdown-toggle {
  &::after {
    border-top: 0.8rem solid;
    border-right: 0.4rem solid transparent;
    border-left: 0.4rem solid transparent;
    vertical-align: 0;
    margin-left: 1.5rem;
  }
}

.show {
  > .btn-secondary.dropdown-toggle {
    background: transparent !important;
    box-shadow: 0 0 !important;
  }
}

.btn-secondary {
  &:not([disabled]) {
    &:not(.disabled) {
      &:active {
        background: transparent !important;
        color: $black;
        box-shadow: 0 0 !important;
      }
    }
  }
}

.show.dropdown-menu {
  background: #fbfcf4;
  border: 0;
  padding: 0;

  button {
    width: 28.5rem;
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important;
    padding-left: 3.6rem !important;
    font-size: 1.6rem !important;
    letter-spacing: 1px;
  }
}

.dropdown {
  .dropdown-menu {
    .dropdown-item {
      &:hover {
        background-color: #f6f7ef !important;
        border-left: 2px solid #211f44;
        color: $black !important;
        box-shadow: 0 0 !important;
      }
    }
  }
}

.pagination {
  align-items: center;
  margin-bottom: 0;

  .select-wrapper {
    input {
      &.select-dropdown {
        padding: 1.5rem 3rem 1.5rem 1rem !important;
        border: 0 !important;
        font-size: 1.8rem;
        margin-bottom: 0;
        max-width: 7rem;
      }
    }

    label {
      position: static;
      padding: 0;
      font-size: 1.8rem !important;
      text-transform: initial;
    }
  }

  .page-item {
    .page-link {
      color: #595959;
      font-size: 1.4rem;
      font-weight: 300;

      img {
        position: relative;
        top: -1.5px;
      }
    }
  }
}

.select-wob {
  &.select-wrapper {
    input {
      &.select-dropdown {
        padding: 1.5rem 3rem 1.5rem 1rem;
        border: 0 !important;
        font-size: 1.6rem;
        margin-bottom: 0;
        max-width: 16rem;
        font-weight: 700;
      }
    }

    label {
      position: static;
      padding: 0;
      font-size: 1.6rem !important;
      text-transform: initial;
      font-weight: 400;
    }
  }
}

.md-accordion {
  .card-header {
    .fa {
      &:before {
        padding: 0.98rem 1rem;
        border: 1px solid #201d41;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        font-size: 1rem;
      }

      padding: 0.3rem 0 0.9rem;
    }

    h5 {
      align-items: center;
      font-size: 2rem;
      line-height: 1.7;
      letter-spacing: 1.25px;
    }
  }

  .card {
    .card-header {
      padding: 2.4rem 0;
    }

    border-bottom: $darkGrayBorder;

    &:first-child {
      border-top: $darkGrayBorder;
    }

    .card-body {
      font-size: 1.4rem;
      padding: 0 0 1.2rem;
    }
  }
}

.vertical-list {
  padding-left: 20px;
  font-size: 1.8rem;
  line-height: 2.2;

  li {
    padding-left: 15px;
    position: relative;

    &:not(:last-child) {
      &:before {
        display: inline-block;
        content: "";
        background: #000;
        width: 1px;
        height: 100%;
        position: absolute;
        left: -14px;
        top: 1.8rem;
      }
    }
  }
}

.list-group.horizontal-list {
  flex-direction: row;

  a {
    border: 0;
    padding-bottom: 4rem;
    border-bottom: $lightGrayBorder;
    font-size: 1.4rem;
    letter-spacing: 1.44px;
    font-weight: 500;
    text-transform: uppercase;
    color: $black;
  }
}

.list-group-item.active {
  background: transparent;
  color: $black;
  position: relative;

  &:after {
    position: absolute;
    content: "";
    display: block;
    width: 100%;
    height: 3px;
    background: $orange;
    left: 0;
    bottom: -3px;
  }
}

// side bar navigation
.flex-column {
  width: 50, 9rem !important;
  background-color: $white;

  .nav-item .nav-link {
    color: $black !important;
    padding-bottom: 3rem;
    padding-top: 3rem;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: 1.5px;
    border-top: $darkGrayBorder;
    text-transform: none;
  }

  .nav-item .nav-link:hover {
    border-left: $blueBorder;
    background-color: #f6f7ef;
  }

  .nav-item .nav-link:active {
    border-left: $blueBorder;
    background-color: #f6f7ef;
  }
}

.product-card {
  border: 1px solid transparent;
  box-shadow: 0 0;
  position: relative;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  height: 100%;
  outline: none;
  width: 43.5rem;
  // height: 48.5rem;

  .view {
    flex: 1;
  }

  &:hover {
    .fa-heart {
      opacity: 1;
      transition: all 0.5s;
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;
      -ms-transition: all 0.5s;
      -o-transition: all 0.5s;
    }
  }

  &:focus {
    border: $lightGrayBorder;
  }

  h4 {
    font-size: 2.4rem;
    letter-spacing: 1.5px;
  }

  p {
    font-size: 1.8rem !important;
    letter-spacing: 1.13px;
    color: $black !important;

    + {
      p {
        font-size: 1.6rem !important;
        font-weight: 500 !important;
        letter-spacing: 1px;
      }
    }
  }

  .card-body {
    min-height: 14.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 0;
    padding-bottom: 7.5rem;
  }

  .fa-heart {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    padding: 1.37rem 1.25rem;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    opacity: 0;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;

    &:before {
      position: relative;
      left: 1px;
      top: 1px;
    }
  }
}

.product-card.small {
  max-height: 14.3rem;

  h4 {
    font-size: 1.6rem;
    letter-spacing: 1px;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.4rem !important;
    letter-spacing: 0.88px;
  }

  .card-body {
    height: 100%;
    padding: 0;
  }

  img {
    max-height: 100% !important;
  }
}

.modal.left {
  .modal-header {
    @include gradient(to bottom, $blueList);
    color: #fff;
    display: flex;
    justify-content: center;
    border-bottom: 0;

    h4 {
      font-size: 2.8rem;
      letter-spacing: 1px;
      font-weight: 400;
      width: 100%;
      text-align: center;
      position: relative;
      padding-top: 4.2rem;
      padding-bottom: 4.4rem;

      button {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        padding: 0;
        margin: 0;
        color: #fff;
        text-shadow: 0 0;
        font-weight: 300;
        opacity: 1;
        font-size: 3.3rem;
      }
    }
  }

  .modal-body {
    padding: 0;

    div {
      padding: 1.7rem;
      border-bottom: $lightGrayBorder;
      display: flex;
      align-items: center;
      border-left: 3px solid transparent;

      i {
        opacity: 0;
        color: $orange;
        margin-right: 1.7rem;
        font-size: 1.5rem;
      }

      p {
        font-size: 1.6rem;
        letter-spacing: 0.89px;
        line-height: 1.875rem;
        margin-left: 3.3rem;
        margin-bottom: 0;
        max-width: 40rem;
      }
    }

    div.active {
      border-left: $orangeBorder;

      i {
        opacity: 1;
      }
    }
  }
}

.modal {
  .modal-full-height {
    // max-width: 64.5rem;
    // width: 64.5rem;
  }
}

.modal-dialog-centered {
  // max-width: 58.5rem;

  .modal-header {
    // @include gradient(to bottom, $blueList);
    color: #fff;
    padding: 2rem;
    display: flex;
    justify-content: center;
    border-bottom: 0;

    h4 {
      font-size: 2rem;
      letter-spacing: 1.25px;
      font-weight: 300;
      width: 100%;
      text-align: center;
      position: relative;
      padding-top: 2.1rem;
      padding-bottom: 2.1rem;
      line-height: 1.7;

      button {
        position: absolute;
        right: 1.6rem;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        padding: 0;
        margin: 0;
        color: #fff;
        text-shadow: 0 0;
        font-weight: 300;
        opacity: 1;
        font-size: 3.1rem;
      }
    }
  }

  .modal-body {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    i {
      font-size: 3.7rem;
      color: #fff;
      background: $orange;
      padding: 1px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;

      &::before {
        position: relative;
        left: 1px;
      }
    }

    p {
      max-width: 33rem;
      text-align: center;
      margin-top: 2rem;
      font-size: 1.8rem;
      letter-spacing: 1px;
      line-height: 1.66;
    }
  }

  .modal-footer {
    display: flex;
    justify-content: center;
    border-top: 0;
    padding-top: 0;
    padding-bottom: 15rem;

    .btn {
      width: 18.1rem;
      line-height: 11px;
      margin: 0.8rem !important;
    }
  }
}

.main-row {
  margin-top: 11rem;
}

.select2-container {
  position: relative;
  .select2-label {
    font-weight: 400;
    font-family: Montserrat;
    text-transform: uppercase;
    margin-top: -0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    line-height: 1.4rem;
    position: absolute;
    background-color: white;
    z-index: 99;
    left: 13%;
    font-size: 1.2rem;
  }
  height: 5rem;
  .select2 {
    border: solid 1px #353452;
    font-size: 1.4rem;
    color: #353452;
    .react-select__control {
      background-color: transparent;
    }
    .react-select__placeholder {
      padding-left: 3.4rem;
    }
    .react-select__single-value {
      padding-left: 3.4rem;
    }
    .react-select__menu {
      border-radius: 0;
      border: 1px solid #353452;
      z-index: 100;
      margin-top: 0;
      border: none;
      width: 100%;
      background-color: #fbfcf5;
      box-shadow: 0 0 0px 1px #353452;
      // box-shadow: none;
    }
  }
  @media screen and (max-width: 768px) {
    margin-top: 1rem;
  }
}
.session-container {
  z-index: 999;
  top: 20%;
  position: fixed;
  font-size: 1.6rem;
  .session-messages-inner {
    border: 1px solid #353452;
    width: 100%;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    background-color: white;
  }
  svg {
    display: none;
  }
}

.border-top-1-black {
  border-top: solid 1px #262639;
}
.border-bottom-1-black {
  border-bottom: solid 1px #262639;
}

.downloadIcon {
  width: 4.5rem;
  height: 4.5rem;
  border: 1px solid $blueLight;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  .downloadIcon-wrapper {
    transition: all 0.2s ease-in-out;
    border-color: $blueLight;
    .downloadIcon-icon {
      transition: all 0.2s ease-in-out;
    }
  }
  &.active,
  &:hover {
    background-color: $blueLight;
    .downloadIcon-wrapper {
      border-color: white;
      .downloadIcon-icon {
        color: white;
      }
    }
  }
}
.downloadWindow {
  position: absolute;
  background: white;
  width: 25rem;
  height: auto;
  bottom: 115%;
  margin: auto;
  left: 0;
  right: 0;
  box-shadow: 0px 0px 5px 0px rgba(224, 224, 224, 0.5);
  .item-wrapper {
    cursor: pointer;
    .downloadIcon-icon {
      transition: all 0.2s ease-in-out;
      border-bottom: 1px solid rgba($blueLight, 0.6);
      color: rgba($blueLight, 0.6);
    }
    p {
      transition: all 0.2s ease-in-out;
      color: rgba($blueLight, 0.6);
      text-align: left;
    }
    &:hover {
      .downloadIcon-icon {
        border-bottom: 1px solid rgba($blueLight, 1);
        color: rgba($blueLight, 1);
      }
      p {
        color: rgba($blueLight, 0.8);
      }
    }
  }
}

@media screen and(max-width: 400px) {
  .select-wrapper {
    span.caret {
      right: -1.5rem;
    }
  }
}
