.modal-dialog {
  .modal-content {
    // top: -13rem;
    box-shadow: none;
    min-height: auto;
    .modal-header {
      .modal-title {
        p,
        h4 {
          color: white;
          text-align: left;
        }
        h4 {
          font-size: 3.2rem;
          padding: 0;
        }
      }
    }
  }
}

.modal-dialog-centered {
  max-width: 130rem;

  .modal-header {
    h4 {
      text-align: left;
    }
  }
  .modal-content {
    background-color: transparent;
    .modal-body {
      padding: 0;
      .container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        .video {
          width: 100%;
          video {
            width: 100%;
          }
        }
      }
    }
  }
}

.modal-backdrop {
  &.show {
    opacity: 0.77;
  }
}
