.md-accordion {
  margin-top: 0 !important;
  .card {
    .card-header {
      background-color: #fbfcf5;
      border-bottom: none;
      margin-bottom: 0;
      h5 {
        font-size: 1.8rem;
        max-width: calc(100% - 41px);
        margin: 0 auto;
      }
    }

    .card-body {
      max-width: calc(100% - 41px);
      margin: 0 auto;
      div {
        text-align: left;
        margin-left: 24px;
        img {
          display: block;
          margin: 0 auto;
        }
        li {
          font-size: 1.4rem;
          margin-bottom: 1.5rem;
        }
      }
    }
  }
}
