@import "./searchInput/searchInput";
@import "./searchResults/searchResults";
#search-container {
  z-index: 1000;
  position: fixed;
  top: 10rem;
  left: 0;
  right: 0;
  max-height: calc(100vh - 10rem);
  -webkit-box-shadow: 0 8px 20px -15px black;
  -moz-box-shadow: 0 8px 20px -15px black;
  box-shadow: 0 8px 20px -15px black;
  background: #fff;
  padding: 3% 15%;
  overflow: auto;
  .close-search-desktop {
    position: absolute;
    right: 10%;
    top: 35%;
    @media screen and(max-width:700px) {
      display: none;
    }
  }
  .prima-spinner-wrapper {
    display: flex;
    justify-content: center;
  }
}
.suggest-container {
  .suggest-results {
    margin: 3rem auto 0;
  }
}

.search-icon {
  font-size: 2.1rem;
  color: white;
  margin-bottom: 2rem;
  margin-right: 1rem;
}

.close-icon {
  display: none;
}

@media screen and(max-width: 1920px) {
  #search-container {
    padding: 3% 9%;
  }
}

@media screen and(max-width: 1650px) {
  #search-container {
    padding: 3% 3%;
  }
}

@media screen and (max-width: 991px) {
  .search-icon {
    margin-bottom: 0;
  }
  #search-container {
    top: 7rem;
  }
}

@media screen and(max-width: 600px) {
  #search-container {
    height: auto;
  }
}
