#search-container {
  #search-results-container {
    margin-top: 8rem;
    h1 {
      font-size: 2.4rem;
      line-height: 1.42;
      color: #1e1b3b;
    }
    .flex-column {
      background-color: white;
    }
    .search-product-widget {
      .product-item-image-row {
        .product-item-image-column {
          width: 13.8rem;
          height: 14.5rem;
        }
        p {
          display: flex;
          align-items: center;
          font-size: 1.4rem;
          line-height: 1.8rem;
        }
      }
    }
    .view-all-wrapper {
      margin-top: 2rem;
      a {
        font-weight: bold;
        font-size: 1.6rem;
        color: #151522;
      }
    }
  }
}
/*Media Queries*/
@media screen and (max-width: 800px) {
  #search-container {
    #search-results-container {
      text-align: center;
      margin-top: 2rem;
    }
    .view-all-wrapper {
      text-align: center;
      margin-left: 0 !important;
      margin-top: 0 !important;
    }
  }
}
@media (max-width: 575.98px) {
  #search-container {
    #search-results-container {
      text-align: left;
      h1 {
        font-size: 1.8rem;
      }
      .search-product-widget {
        .product-widget-grid {
          .product-item-container {
            margin-top: 1rem !important;
            .product-item-image-row {
              justify-content: start;
            }
          }
        }
      }
      .view-all-wrapper {
        a {
          font-size: 1.4rem;
        }
      }
    }
  }
}
