.related-products-row {
  margin-top: 12rem;
  .related-products-title {
    padding-bottom: 7rem;
    hr {
      width: 100%;
      border-top: 1px solid #e1e1e1;
    }
    .h5 {
      white-space: nowrap;
      padding: 0 5rem;
      letter-spacing: 1.75px;
    }
  }
}
.ScrollbarsCustom {
  .ScrollbarsCustom-TrackX {
    bottom: 8% !important;
    @media screen and(max-width: 800px) {
      bottom: 4rem !important;
    }
    @media screen and(max-width: 480px) {
      bottom: 12rem !important;
    }
  }
}
.slider-related-products {
  width: 100% !important;
  height: 53.8rem !important;
  @media screen and(max-width: 1500px) {
    height: 50rem !important;
  }
  @media screen and(max-width:768px) {
    height: 42rem !important;
  }

  .ScrollbarsCustom-Track {
    border-radius: 0 !important;
    .ScrollbarsCustom-Thumb {
      bottom: 2%;
      background: #1e1c3a !important;
      border-radius: 0 !important;
    }
  }
  .ScrollbarsCustom-Content {
    display: inline-flex;
  }

  .related-products-pdp {
    @media screen and (max-width: 1401px) {
      width: 36rem !important;
    }
    @media screen and(max-width: 1280px) {
      width: 38rem !important;
    }
    @media screen and(max-width: 1100px) {
      width: 50rem !important;
    }
    @media screen and(max-width: 1000px) {
      width: 32.5rem !important;
    }

    @media screen and (max-width: 767px) {
      width: 25rem !important;
    }
    @media screen and(max-width: 500px) {
      width: 22rem !important;
    }
    @media screen and(max-width: 450px) {
      width: 18.5rem !important;
    }
    .view {
      display: flex;
      justify-content: center;
      .Ripple-parent {
        img {
          height: 30rem;
          @media screen and (max-width: 768px) {
            height: 25rem;
          }
          @media screen and (max-width: 600px) {
            height: 20rem;
          }
          @media screen and(max-width: 480px) {
            height: 14rem;
          }
        }
      }
    }
  }
}
/*Media Queries*/
@media (max-width: 575.98px) {
  .related-products-row {
    margin-top: 4rem;
    .related-products-title {
      padding-bottom: 4.5rem;
      h5 {
        &.h5 {
          font-size: 2rem;
          padding: 0 2rem;
        }
      }
    }
    .slider-related-products {
      .product-card {
        .card-body-pdp-page {
          .card-text {
            font-size: 1.4rem !important;
          }
        }
      }
    }
  }
}
