// TODO: refactor everything
.new-products-section {
  padding: 10rem 0 10rem;
  .section-title {
    padding-bottom: 7rem;
    hr {
      width: 100%;
      border-top: 1px solid #e1e1e1;
    }
    .h5 {
      white-space: nowrap;
      padding: 0 5rem;
      letter-spacing: 1.75px;
      font-size: 2.8rem;
      font-family: SangBleuSunriseRegular !important;
    }
  }
  .ScrollbarsCustom {
    width: 100% !important;
    height: 58.5rem !important;
    @media screen and(max-width: 1500px) {
      height: 50rem !important;
    }
    @media screen and(max-width:768px) {
      height: 35rem !important;
    }
    .ScrollbarsCustom-Track {
      border-radius: 0 !important;
      .ScrollbarsCustom-Thumb {
        border-radius: 0 !important;
        background: #1e1c3a !important;
      }
    }
    .ScrollbarsCustom-TrackX {
      bottom: 12% !important;
      @media screen and(max-width: 1000px) {
        bottom: 0 !important;
      }
      @media screen and(max-width: 480px) {
        bottom: 10% !important;
      }
    }
    .ScrollbarsCustom-Content {
      display: inline-flex;
    }
  }
  .new-products-home-page {
    @media screen and (max-width: 1401px) {
      width: 38rem;
    }
    @media screen and(max-width: 1201px) {
      width: 32rem;
    }
    @media screen and(max-width: 1000px) {
      width: 38rem;
    }
    @media screen and (max-width: 768px) {
      width: 23rem;
    }
    @media screen and (max-width: 480px) {
      width: 20rem;
      a {
        .card-body {
          p {
            font-size: 1.4rem !important;
          }
          h4 {
            a {
              font-size: 1.4rem;
              white-space: nowrap;
            }
          }
        }
      }
    }
    @media screen and(max-width: 400px) {
      width: 15rem;
    }
    .view {
      display: flex;
      justify-content: center;

      .Ripple-parent {
        img {
          height: 30rem;
          margin-bottom: 2rem;
          @media screen and (max-width: 768px) {
            height: 20rem;
          }
          @media screen and(max-width: 480px) {
            height: 14rem;
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .new-products-section {
    padding: 5rem 3rem 10rem;
    .section-title {
      .h5 {
        font-size: 2rem;
        padding: 0 2rem;
      }
    }
  }
}
