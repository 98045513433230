@import "./productItem/productItem";
.product-widget-container {
  .product-item {
    .entering-direction-forward-done {
      top: 0;
    }
  }
  &.view-grid {
    padding: 0;
    max-width: 100%;
  }
  &.view-slider {
    padding: 0;
    .slider-title {
      padding-bottom: 6rem;

      p {
        line-height: 3.4rem;
        letter-spacing: 0.14rem;

        .style-element {
          width: 2.5rem;
          margin-right: 1rem;
          height: 1px;
        }
      }

      .slider-arrows-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        svg {
          cursor: pointer;
          margin: 0 1rem;
          transition: all 0.5s ease-in-out;
        }
        .disabled {
          opacity: 0.5;
          transition: all 0.5s ease-in-out;
        }
      }
    }
    .product-widget-slider {
      display: grid;
      grid-column-gap: 2rem;
      grid-row-gap: 2rem;
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .product-widget-slider-dots {
      margin-top: 2rem;
      padding: 2rem;
      .list-custom-dots {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .slider-custom-dot {
          transition: all 0.5s ease-in-out;
          &.active {
            height: 0.8rem;
            width: 0.8rem;
            color: transparent;
            border-radius: 1px;
            border: none;
            // background: $primary-color;
            transition: all 0.5s ease-in-out;
          }
        }
      }
    }
  }
  .product-widget-grid {
    display: grid;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    .product-grid-item {
      padding: 0 2rem;
    }
  }
}
@media screen and (max-width: $screen__ml) {
  .product-widget-container {
    .placeholder-products {
      display: flex;
      .placeholder-product-item {
        display: none;
        &:nth-of-type(-n + 3) {
          display: block;
        }
      }
    }
  }
}

@media screen and (max-width: 1366px) {
  .product-widget-container {
    .product-widget-grid {
      grid-template-columns: 1fr 1fr 1fr;
    }
    &.view-slider {
      .product-widget-slider {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
    .placeholder-products {
      display: flex;
      .placeholder-product-item {
        display: none;
        &:nth-of-type(-n + 3) {
          display: block;
        }
      }
    }
  }
}

@media screen and(max-width:992px) {
  .product-widget-container .product-widget-grid {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
  }
  .product-widget-container {
    &.view-slider {
      .product-widget-slider {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}

@media screen and(max-width: 700px) {
  .product-widget-container .product-widget-grid {
    grid-template-columns: 1fr;
  }
  .product-widget-container {
    &.view-slider {
      .product-widget-slider {
        grid-template-columns: 1fr;
      }
    }
  }
}

@media screen and(max-width:320px) {
  .product-widget-container .product-widget-grid {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }
}
