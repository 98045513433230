.address-book-form {
  .select2-container .select2 {
    border: solid 1px #979797;
  }

  .select2-container {
    // padding: 0 1.9rem;
    margin-bottom: 3rem;
    .select2 {
      border: solid 1px #979797 !important;
      border-radius: 0;
      &:focus {
        outline: none;
        box-shadow: 0 1px 0 0 #4285f4;
      }
      .react-select__menu-list {
        div {
          font-family: SangBleuSunrise !important;
        }
        .react-select__value-container--has-value {
          .react-select__single-value {
            div > div {
              font-family: SangBleuSunriseRegular !important;
            }
          }
        }
        background-color: #ffffff;
        font-family: SangBleuSunrise !important;
        letter-spacing: 0.1rem;
        .react-select__option {
          font-family: SangBleuSunrise !important;
        }
        // react-select__option--is-selected
      }
      .react-select-container {
        .react-select__placeholder {
          font-family: SangBleuSunrise !important;
          padding-left: 0;
        }
        &:focus {
          outline: none;
        }
      }
      .react-select__control {
        border-radius: 0;
        border: none;
        padding: 0.2rem 0;
        &.react-select__control--is-focused {
          box-shadow: none;
        }
      }
    }
    .select2-label {
      background-color: #fbfcf5;
      left: 2.5rem;
      top: -0.3rem;
      font-size: 14px !important;
      // letter-spacing: 0.75px;
      font-weight: 400;
      color: #000000;
    }
    .react-select__value-container {
      padding-left: 2.7rem;
      font-family: SangBleuSunrise;
    }
    .react-select__single-value {
      padding-left: 0 !important;
      font-size: 1.4rem;
      margin: 0;
      font-family: SangBleuSunrise;
      color: #495057;
    }
  }
  .account-input {
    margin: auto;
    height: 50px;
    border: solid 1px #979797 !important;
    position: relative;
    padding-left: 2rem;
  }

  .account-input-label {
    font-family: Montserrat;
    font-size: 12px !important;
    letter-spacing: 0.75px;
    color: #000000;
    background: white;
    left: 2rem;
    top: -1rem;
    padding-right: 5px;
    padding-left: 5px;
  }

  .account-input-error {
    border: solid 1px #f83535 !important;
  }

  .account-error-label {
    color: #f83535 !important;
  }

  .account-error-message {
    font-family: Montserrat;
    font-size: 12px;
    color: #f83535;
    margin-top: -30px;
    margin-bottom: 30px;
  }
}
