//client originally  wanted this section with widgets, so if something changes uncomment this
.position-one,
.position-two,
.other-position {
  .widget-and-title-wrapper {
    max-height: 36rem;
    display: flex;
    position: absolute;
    top: 0;
    justify-content: center;
    // .widget-image {
    //   max-width: 34rem;
    //   max-height: 35rem;
    //   margin-right: 7rem;

    //   @media screen and(max-width: 1000px) {
    //     margin-right: 3rem;
    //   }
    //   @media screen and(max-width: 1400px) {
    //     margin-right: 3rem;
    //     max-height: 30rem;
    //   }
    // }
    h6 {
      @media screen and(max-width: 1400px) {
        // margin-right: -5.5rem;
      }
      @media screen and(max-width: 992px) {
        margin-top: 0rem;
      }
      margin-top: 4rem;
      // margin-right: -7.5rem;
      font-size: 2.2rem;
      text-align: center;
      padding: 2rem;
    }
  }
}
.position-three {
  .widget-and-title-wrapper {
    text-align: center;
    max-height: 36rem;
    display: flex;
    flex-direction: row-reverse;
    position: absolute;
    top: 0;
    // .widget-image {
    //   max-width: 34rem;
    //   max-height: 35rem;
    //   @media screen and(max-width: 1400px) {
    //     margin-left: 3rem;
    //     max-height: 30rem;
    //   }
    //   margin-left: 7rem;
    //   @media screen and(max-width: 1000px) {
    //     margin-left: 2rem;
    //   }
    // }
    h6 {
      @media screen and(max-width: 992px) {
        margin-top: 0rem;
      }
      // margin-left: -11.8rem;
      padding-left: 6rem;
      margin-top: 4rem;
      font-size: 2.2rem;
      text-align: center;
      padding: 2rem;
    }
  }
}

.position-four,
.position-five {
  .widget-and-title-wrapper {
    display: flex;
    position: absolute;
    bottom: 0;
    @media screen and(max-width: 1200px) {
      top: 3.2%;
    }
    @media screen and(max-width:992px) {
      top: 0;
    }

    margin-bottom: 3.2rem;
    // .widget-image {
    //   max-height: 18rem;
    //   margin-right: 7rem;
    //   @media screen and(max-width: 1400px) {
    //     margin-right: 0;
    //   }
    //   @media screen and(max-width: 1000px) {
    //     margin-right: 0rem;
    //   }
    // }
    h6 {
      @media screen and(max-width: 992px) {
        margin-top: 0rem;
      }
      margin-top: 2.5rem;
      // margin-right: -9rem;
      font-size: 2.2rem;
      text-align: center;
      padding-right: 4rem;
    }
  }
}

.position-five {
  .widget-and-title-wrapper {
    display: flex;
    position: absolute;
    bottom: 0;
    @media screen and(max-width: 1200px) {
      top: 0;
    }
    @media screen and(max-width: 500px) {
      top: 0;
    }

    margin-bottom: 3.2rem;
    // .widget-image {
    //   max-height: 18rem;
    //   margin-right: 7rem;
    //   @media screen and(max-width: 1400px) {
    //     margin-right: 0;
    //   }
    //   @media screen and(max-width: 1000px) {
    //     margin-right: 0rem;
    //   }
    // }
    h6 {
      margin-top: 2rem;
      @media screen and(max-width: 992px) {
        margin-top: 0rem;
      }
      // margin-right: -9rem;
      font-size: 2.2rem;
      text-align: center;
      padding-right: 4rem;
    }
  }
}

.position-six {
  .widget-and-title-wrapper {
    display: flex;
    flex-direction: row-reverse;
    position: absolute;
    bottom: 0;
    margin-bottom: 3.2rem;
    @media screen and(max-width: 1200px) {
      // top: 3.2%;
      top: 0;
    }
    @media screen and(max-width: 500px) {
      top: 0;
    }
    // .widget-image {
    //   max-height: 19rem;
    //   max-width: 18rem;
    //   margin-left: 7rem;
    //   @media screen and(max-width: 1400px) {
    //     margin-left: 0;
    //   }
    //   @media screen and(max-width: 1000px) {
    //     margin-left: 3rem;
    //   }
    // }
    h6 {
      @media screen and(max-width: 992px) {
        margin-top: 0rem;
      }
      margin-top: 2rem;
      // margin-left: -10rem;
      font-size: 2.2rem;
      letter-spacing: 0;
      text-align: center;
      padding-left: 6rem;
    }
  }
}

@media screen and(max-width: 500px) {
  .position-one,
  .position-two,
  .position-three,
  .other-position {
    .widget-and-title-wrapper {
      margin-bottom: 0;
      display: flex;
      flex-direction: unset;
      max-height: 15rem;
      bottom: 34%;
      // .widget-image {
      //   margin-right: 0;
      //   height: 14rem;
      //   margin-bottom: 0;
      // }
      h6 {
        margin-top: 0rem !important;
        // margin-right: -2.5rem;
        margin-left: 0;
        padding-left: 1rem;
        font-size: 1.6rem !important;
      }
    }
  }
  .position-four,
  .position-five {
    .widget-and-title-wrapper {
      margin-bottom: 0;
      bottom: 50%;
      // .widget-image {
      //   height: 11rem;
      //   width: 9rem;
      //   object-fit: fill;
      //   padding-top: 2rem;
      // }

      h6 {
        padding-left: 1rem;
        font-size: 1.6rem !important;
        letter-spacing: 0.16rem;
      }
    }
  }
  .position-six {
    .widget-and-title-wrapper {
      flex-direction: unset;
      margin-bottom: 0;
      bottom: 44%;
      // .widget-image {
      //   padding-top: 2rem;
      //   object-fit: fill;
      //   margin-left: -5rem;
      //   height: 11rem;
      //   width: 9rem;
      // }
      h6 {
        font-size: 1.6rem !important;
        letter-spacing: 0.16rem;
        // margin-left: 0;
        padding-left: 1rem;
        margin-right: -4rem;
        padding-right: 3rem !important;
      }
    }
  }
}
