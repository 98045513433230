.reset-password-page {
  padding-right: 0;
  padding-left: 0;
  justify-content: center;
  display: flex;
  text-align: center;
  .md-form {
    input {
      margin-top: 2rem !important;
      margin-bottom: 2rem !important;
      width: 35rem;
      @media screen and(max-width: 600px) {
        width: 30rem;
      }
      background-image: none !important;
      background-color: #fff !important;
    }
    .account-input {
      background-color: #fff;
      margin: auto;
      height: 50px;
      border: solid 1px #979797 !important;
      position: relative;
      padding-left: 2rem;
    }
    .account-input-label {
      font-family: Montserrat;
      font-size: 12px !important;
      letter-spacing: 0.75px;
      color: #000000;
      background: white;
      left: 2rem;
      top: -1rem;
      padding-right: 5px;
      padding-left: 5px;
    }

    .account-input-error {
      border: solid 1px #f83535 !important;
    }
    .account-error-label {
      color: #f83535 !important;
    }
    .account-error-message {
      font-family: Montserrat;
      font-size: 12px;
      color: #f83535;
      margin-top: -30px;
      margin-bottom: 30px;
    }
  }

  input::placeholder {
    font-size: 1.6rem;
  }

  .reset-password-page-form-wrapper {
    margin-top: 10rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    align-items: center;
  }

  h1 {
    margin-bottom: 3rem;
    margin-left: 0.5rem;
    font-size: 3.2rem;
    line-height: 2.9rem;
    color: #363b45;
    @media screen and(max-width:400px) {
      font-size: 2.5rem;
    }
  }
  p {
    line-height: 2.6rem;
    margin-left: 0.5rem;
    font-size: 1.6rem;
    color: #363b45;
  }
}

@media screen and (max-width: 420px) {
  .reset-password-page > img {
    height: 62rem!important;
  }
}
