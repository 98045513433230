.all-categories {
  overflow: hidden;
  margin-right: 0;
  .textual-block {
    max-height: 50rem;
    min-height: 100%;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    background-color: #15152a;
    @media screen and(min-width: 1921px) {
      max-height: 57rem;
    }
    @media screen and(max-width: 992px) {
      height: 20rem;
      justify-content: center;
    }

    @media screen and(max-width: 550px) {
      max-height: 33rem;
    }
    h6 {
      margin-left: 8rem;
      width: 25rem;
      position: relative;
      font-family: SangBleuSunriseRegular;
      font-size: 4.2rem;
      font-weight: 300;
      letter-spacing: 2px;
      color: #ffffff;
      @media screen and(min-width:991px) and (max-width: 1024px) {
        margin: auto;
      }
      @media screen and(max-width: 500px) {
        text-align: center;
        font-size: 2.4rem;
        width: 100%;
        margin-left: 0;
        // svg {
        //   position: absolute;
        //   left: 15% !important;
        //   top: -120% !important;
        //   max-height: 10rem !important;
        // }
      }
      svg {
        position: absolute;
        left: 14%;
        top: 0;
      }
    }
  }
  .single-category {
    max-height: 50rem;
    @media screen and(min-width: 1921px) {
      max-height: 57rem;
    }

    @media screen and(max-width: 550px) {
      max-height: 33rem;
    }
    //check file bellow  for widget style
    @import "./different_positions";
    .images-holder {
      .category-main-image {
        width: 100%;
      }
      img {
        height: 100%;
        @media screen and(min-width: 1921px) {
          object-fit: cover;
        }
        @media screen and(max-width: 1921px) {
          object-fit: fill;
        }
        @media screen and(max-width: 600px) {
          object-fit: cover;
        }
      }
      .widget-and-title-wrapper {
        width: 100%;
        // img {
        //   object-fit: scale-down;
        // }
        h6 {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #d8d8d8;
          background: rgba(251, 252, 245, 0.4);
          font-size: 2.2rem;
          height: 12rem;
          font-weight: 400;
          line-height: 1.42;
          color: $black;
          font-family: $fontFamily;
          @media screen and(min-width:991px) and (max-width: 1024px) {
            margin: 0;
          }
          @media screen and(max-width:500px) {
            height: 8rem;
          }
        }
        @media screen and(min-width:991px) and (max-width: 1024px) {
          top: 3.2%;
        }
      }
    }
  }
}
