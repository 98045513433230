.ol-connected {
  list-style: none;
  counter-reset: connectedOl;
  li {
    padding-bottom: 1rem;
    padding-top: 1rem;
    position: relative;
    &:before {
      position: absolute;
      border-radius: 100%;
      display: flex;
      color: white;
      top: 2rem;
      left: -4.3rem;
      background-color: #211f44;
      width: 0.7rem;
      height: 0.7rem;
      align-items: center;
      justify-content: center;
      content: "";
      z-index: 2;
    }
    &:after {
      background-color: #211f44;
      position: absolute;
      width: 1px;
      height: calc(100% - 0.7rem);
      display: flex;
      color: white;
      content: "";
      top: 2.7rem;
      z-index: 1;
      left: -4rem;
    }
    &:last-child {
      &:after {
        content: none;
      }
    }
  }
  &.withNumber {
    li {
      &:before {
        background-image: linear-gradient(60deg, #dde1f2 4%, #a2a5be 93%);
        width: 3rem;
        left: -60px;
        height: 3rem;
        counter-increment: connectedOl;
        content: counter(connectedOl);
        z-index: 1;
      }
      &:after {
        background-color: #adb0c8;
        height: calc(100% - 1.5rem);
        left: -4.5rem;
        top: 3rem;
      }
    }
  }
}
