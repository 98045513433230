.downloads-wrapper {
  padding: 0 1.5rem;
  position: relative;
  .downloads-text {
    margin-left: 1.5rem;
  }
}
.tear-sheet {
  transform: none;
  background-color: #ffffff;
  width: 100%;
  min-height: 535px;
  border: solid 1px #e1e1e1;
  padding: 0px;
  max-width: 65rem !important;
  .modal-header {
    color: #fff;
    padding: 2rem;
    display: flex;
    justify-content: center;
    border-bottom: 0;
    background-image: linear-gradient(to bottom, #151521, #211f44);
    svg > g > g {
      stroke: white !important;
    }
  }
  .modal-body {
    padding: 3rem;
    .modal-actions.mt-5 button {
      margin: 0 auto;
      display: block;
    }
  }
  .modal-content {
    position: absolute;
    top: 0;
    span.error {
      color: red;
      font-size: 1.4rem;
      margin-left: 10px;
    }
    .form-check {
      margin-bottom: 15px;
      &:nth-of-type(2) {
        margin-bottom: 4px;
      }
    }
    .md-form.form-group.enter-value-container {
      width: 100px;
      margin-left: 25px;
    }
  }
}
// .heart-holder {
//   position: absolute;
//   right: 59px;
//   top: 92%;
//   z-index: 15;
//   &:hover {
//     cursor: pointer;
//   }
// }
@keyframes pulseTwo {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.7);
  }

  50% {
    transform: scale(1.1);
  }

  75% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
}

.wishlist-icon-container {
  z-index: 1;
  transform: scale(1);
  cursor: pointer;
  width: 100%;
  text-align: right;
  // position: absolute;
  // left: 15px;
  z-index: 1;
  &.mobile-icon {
    position: relative;
    top: 3rem;
    right: 2.5rem;
    width: 25%;
    margin-left: auto;
  }
  &.loading {
    svg {
      animation: pulseTwo 2s infinite;
    }
  }
}

.question-box-tooltip-wrapper {
  position: absolute;
  top: 5rem;
  z-index: 99;
  border: 1px solid #1f1e3f;
  border-radius: 0.5rem;
  font-size: 1.4rem;
  padding: 1rem 1.2rem;
  color: black;
  background-color: #ffffff;
  font-weight: 400;
  p {
    font-family: Montserrat !important;
    text-align: left;
    white-space: nowrap;
    font-size: 1.2rem !important;
    letter-spacing: 0.75px;
    color: #000000;
  }
}

.span-logo {
  svg {
    // visibility: hidden;
  }
}

.download-section-wrapper {
  position: relative;
  display: flex;
  // justify-content: space-between;
  margin: 2.6rem 0 3.6rem 0;
  & > * + * {
    margin-left: 3.5rem;
  }

  .span-logo {
    &:hover {
      cursor: pointer;
    }

    span {
      font-family: Montserrat;
      font-size: 1rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.1rem;
      color: #1f1e3f !important;
      visibility: hidden;
    }
    a {
      display: flex;
      align-items: center;
    }
  }

  .download-box {
    opacity: 1;
    position: absolute;
    z-index: 5;
    top: 115%;
    right: -3.5rem;
    background: #fbfcf5;
    max-width: 40rem;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.19);
    border: solid 1px #e1e1e1;

    // .download-arrow {
    //   margin-left: 3rem;
    //   display: none;
    // }

    // p:hover {
    //   border-left: 3px solid #211f44;
    //   border-bottom: 1px solid #e1e1e1;
    //   background: #f6f7ef;

    //   .download-arrow {
    //     display: inline;
    //   }
    // }

    .pdf-title {
      border-bottom: 1px solid #e1e1e1;
      padding: 2rem;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 1px;
      color: #151522;
    }

    .pdf-p-tag {
      width: 100%;
      display: flex;
      padding: 1rem;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1px;
      color: #151522;
      border-left: 4px solid transparent;
      transition: all 0.4s ease-in-out;
      padding-left: 0;

      &:hover {
        background-color: #f6f7ef;
        border-left: 4px solid #211f44;
      }

      .pdf-a-tag {
        display: flex;
        width: 100%;
        p {
          align-self: center;
          pointer-events: none;
        }
        .download-arrow {
          margin-left: auto;
        }
      }
    }

    .ies-title {
      border-bottom: 1px solid #e1e1e1;
      padding: 2rem;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 1px;
      color: #151522;
    }

    .ies-p-tag {
      padding: 1rem;
      font-size: 16px !important;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1px !important;
      color: #151522 !important;
    }
  }
}
/*Media Queries*/
@media screen and (max-width: 991.98px) {
  .wrapper-favorite {
    flex-direction: column;
  }
  .downloads-text {
    margin-left: 0;
    margin-top: 5px;
  }
  .downloads-wrapper > div {
    flex-direction: column;
  }
  .download-section-wrapper {
    .download-box {
      right: auto;
      left: 10rem;
    }
  }
}
@media (max-width: 575.98px) {
  .download-section-wrapper {
    .download-box {
      p {
        font-size: 1.4rem !important;
        margin-left: 0 !important;
        padding: 1rem !important;
      }
    }
  }
}

@media print {
  #footer.footerSpechseet {
    bottom: -10px;
    position: fixed;
  }
  @page {
    size: auto; /*A4*/
    margin: 0; /*webkit says no*/
  }
}
