.faq-sidebar-wrapper {
  background-color: #fbfcf5;
  height: 100rem;
  @media screen and(max-width: 1500px) {
    height: unset;
    margin-bottom: 2rem;
  }
  .faq-sidebar {
    @media screen and(max-width: 600px) {
      ul {
        padding-left: 0;
        display: flex;
        overflow: scroll;
        .faq-sidebar-link {
          font-size: 1.4rem;
          text-transform: uppercase;
          line-height: 2rem;
          text-align: center;
          padding-right: 2rem;
          padding-left: 2rem;
        }
      }
      .active {
        border-bottom: 4px solid #15152a !important;
        border-left: none !important;
        background-color: #353452;
      }
    }
    .faq-sidebar-link {
      letter-spacing: 0.12rem;
      border-bottom: 1px solid #e1e1e1;
      align-items: center;
      padding-left: 7rem;
      height: 10rem;
      width: 100%;
      background-color: #fbfcf5;
      color: #211f44;
      font-family: SangBlueSunriseTrial;
      font-size: 2.4rem;
      font-weight: normal;
      @media screen and(max-width: 1500px) {
        padding-left: 2rem;
      }
    }
    .active {
      border-left: 4px solid #15152a;
    }
  }
}
