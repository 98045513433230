%with-appear-transition {
  opacity: 0;
  visibility: hidden;
  transition: all 300ms ease-in-out;
}
%appear {
  opacity: 1;
  visibility: visible;
}

.wishlist-modal {
  .modal-body {
    padding-top: 30px !important;
  }
  button {
    margin-bottom: 0 !important;
    line-height: 9px;
    &.cancel {
      width: 180px;
      height: 50px;
      padding: 16px 54px;
      margin-right: 25px;
    }
    &.confirm {
      width: 180px;
      height: 50px;
      padding: 16px 54px;
      border: solid 1px #000000;
      color: black;
      background-color: white !important;
      background: white !important;
      &:focus {
        background-color: white !important;
        background: white !important;
        color: black;
      }
    }
  }
}

@keyframes pulseTwo {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.7);
  }

  50% {
    transform: scale(1.1);
  }

  75% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
}

.single-product {
  width: 100%;
  height: 100%;
  margin: auto;
  text-align: center;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .wishlist-part {
    align-items: center;
    margin-top: 15px;
    .quote-holder {
      margin: 7px 104px 7px 8px;
      background-color: #f2ab21;
      border-radius: 50%;
      padding: 15px;
    }
    button {
      padding: 16px 21px !important;
      margin-right: 15px;
    }
    svg {
      cursor: pointer;
    }
  }
  .heart {
    @extend %with-appear-transition;
    position: absolute;
    // display: none;
    right: 15px;
    z-index: 15;
  }
  > div {
    @media screen and(max-width:992px) {
      min-height: 200px;
      max-height: 200px;
    }
  }
  .flex-1 {
    .nav-link {
      img {
        max-width: 30rem;
        width: 100%;
        max-height: 303px;
        @media screen and (min-width: 993px) {
          max-height: auto;
          min-height: 303px;
        }
      }
    }
  }

  &:hover {
    box-shadow: 0 0 0.1rem 0.1rem lightgray;
    .favorite {
      @extend %appear;
    }
  }
  &:hover > .heart {
    // display: block;
    @extend %appear;
  }
  .img-wrapper {
    height: 380px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .flex-column {
    background: transparent;
    h2 {
      font-family: Montserrat;
      font-size: 2.4rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1.5px;
      text-align: center;
      color: #000000;
      @media screen and(max-width: 1440px) {
        font-size: 1.8rem;
      }
      @media screen and (max-width: 1024px) {
        font-size: 1.6rem;
      }
    }

    p {
      font-family: Montserrat;
      font-size: 1.8rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1.13px;
      text-align: center;
      color: #000000;
      @media screen and(max-width: 1440px) {
        font-size: 1.2rem;
      }
    }
  }
}

@media (max-width: 576px) {
  .single-product {
    // height: 27.9rem;
    .img-wrapper {
      height: 150px !important;
    }
    h1 {
      margin-top: 15% !important;
      font-size: 16px;
      line-height: 1.88;
      letter-spacing: 0.89px;
      color: #49494f;
    }
    p {
      font-size: 12px;
      letter-spacing: 0.75px;
      text-align: center;
    }
  }
}
