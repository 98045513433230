.find-rep-container {
  a {
    text-transform: none;
  }
  overflow: hidden;
  @import "./form/form";
  @import "./content/repCard/repCard";
  @import "./toolbar/toolbar";
  margin-right: 0;
  margin-left: 0;
  padding-top: 10rem;
  @media screen and(max-width: 1400px) {
    padding-top: 10rem;
  }
  @media screen and(max-width: 992px) {
    padding-top: 7rem;
  }
  .image-and-toolbar-wrapper {
    padding-right: 0;
    padding-left: 0;
    .imageloader-loaded {
      img {
        max-height: 48.7rem;
        max-width: 150rem;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .find-a-rep-main-content {
      margin-left: 5rem;
      @media screen and(max-width: 1500px) {
        margin-left: 0rem;
      }
      width: 100%;
      margin-top: 5rem;
      .button-container {
        @media screen and (max-width: 991.98px) {
          margin-top: 2rem;
          button {
            width: 50%;
          }
        }
        .reset-btn {
          margin-left: 2rem;
          background-color: #ffffff !important;
          color: #363354;
          border: 1px solid #363354;
        }
        button {
          font-size: 1.4rem;
          width: 20rem;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 5rem;
          @media screen and (max-width: 768px) {
            width: 50%;
            margin-bottom: 5rem;
          }
        }
      }
    }
  }
}
/*Media Queries*/
@media (max-width: 575.98px) {
  .find-rep-container {
    .find-a-rep-main-content {
      .h1 {
        font-size: 2rem;
      }
    }
  }
}
