.tabs-section {
  background: #fbfcf5;

  .h6 {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    font-size: 1.8rem;

    span {
      font-size: 1rem;
    }
  }

  .tabs-header {
    border-top: $lightGrayBorder;
    border-bottom: $lightGrayBorder;

    .h5 {
      margin-right: 10rem;
      margin-left: 5rem;
    }

    .tabs {
      width: 100%;
      background: $white;
      border: none !important;

      ul {
        display: flex;
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;

        li {
          font-size: 1.4rem;
          font-weight: 500;
          color: #1e1b3b;
          padding: 3.5rem 2rem;
          position: relative;
          cursor: pointer;
          text-transform: uppercase;
          white-space: normal;

          &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 8px;
            height: 3px;
            width: 90%;
            background: #f29823;
            transform: scaleX(0);
            -webkit-transform: scaleX(0);
            -moz-transform: scaleX(0);
            -ms-transform: scaleX(0);
            -o-transform: scaleX(0);
            transition: all 0.3s;
            -webkit-transition: all 0.3s;
            -moz-transition: all 0.3s;
            -ms-transition: all 0.3s;
            -o-transition: all 0.3s;
            transform-origin: top left;
          }

          &:hover {
            &:after {
              transform: scaleX(1);
              -webkit-transform: scaleX(1);
              -moz-transform: scaleX(1);
              -ms-transform: scaleX(1);
              -o-transform: scaleX(1);
            }
          }
        }
      }
    }
  }

  .tabs-container {
    border-bottom: $lightGrayBorder;
    border-left: $lightGrayBorder;
    border-right: $lightGrayBorder;
    margin: 0 3%;
    .main-img {
      padding: 0;
      margin: 0;
    }
    .main-img img {
      width: 100%;
    }

    .text-part {
      padding: 3rem 6rem;
      width: 100%;

      .h3 {
        line-height: 1.61;
      }
      p {
        margin-left: 6rem;
        font-family: Montserrat;
        font-size: 17px;
        font-weight: 400;
        line-height: 1.67;
        letter-spacing: 1px;
        color: #49494f;
        margin-top: 2.5rem;
      }
    }
  }

  .main-block-tabs {
    width: 100%;
    padding: 0;

    padding-top: 5rem;

    .product-image-right {
      padding: 0;
      img {
        width: 100%;
      }
    }

    .product-image-left {
      padding: 0;
      padding-left: 5%;

      img {
        width: 100% !important;
        margin-bottom: 5rem;
      }

      span {
        display: inline-block;
        text-align: center !important;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: 0.88px;
        text-align: center;
        color: #15152a;
      }
    }
  }
  .image-left-wrapper {
    display: inline-block;

    p {
      font-size: 1.4rem;
      &:nth-child(3) {
        margin-bottom: 2.3rem;
      }
    }

    .card-title {
      margin-top: 5%;
      margin-left: 6%;
    }

    .card-text {
      margin-top: 1%;
      margin-left: 5%;
    }
  }
}

@media (max-width: 1200px) {
  .flex-column-navigation-mobile-about {
    display: block !important;
    overflow: auto !important;
    white-space: pre !important;
    li {
      font-size: 1.2rem !important;
      padding: 2rem 2rem !important;
      color: #1e1b3b !important;
      width: 30%;
      display: inline-block !important;
      text-align: center !important;
    }

    .nav-item .nav-link {
      font-family: Montserrat;
      font-size: 4.6vw !important;
      font-weight: 500;
      line-height: 1;
      letter-spacing: 0.5px !important;
      color: #1e1b3b;
      padding: 2rem 1.5rem !important;
      border-top: none;
      white-space: normal !important;
      border-top: none !important;
      text-align: center;
    }

    li .active-link {
      font-family: Montserrat;
      font-size: 22px !important;
      font-weight: 500;
      line-height: 1;
      letter-spacing: 0.5px !important;
      color: #1e1b3b;
      border-bottom: 3px solid #15152a !important;
      border-left: none;
      background-color: #f6f7ef;
      border-top: none !important;
      text-align: center !important;
    }
  }
}

@media (max-width: 1024px) {
  .tabs-section {
    .tabs-header {
      .h5 {
        margin-left: 3rem;
      }
    }
    .tabs-container {
      border: unset;
      .text-part {
        p {
          margin-left: 0;
        }
      }
    }
  }
}

@media (min-width: 767px) and (max-width: 991.98px) {
  .tabs-section {
    .tabs-container {
      .text-part {
        padding: 3rem 0;
      }
    }
  }

  .main-block-tabs {
    .product-image-right {
      img {
        margin-top: 15%;
        padding-right: 5%;
        width: 100%;
        min-width: 17.7rem;
        min-height: 17.7rem;
      }
    }

    .product-image-left {
      padding: 0;
      padding-left: 5%;

      img {
        padding: 0 5%;
        margin-bottom: 10%;
        width: 50%;
      }
    }
  }
}

@media (max-width: 767px) {
  .tabs-section {
    .tabs-inner {
      .h5 {
        padding: 4rem 0 0;
        width: 100%;
        text-align: left;
        margin-left: 1rem;
      }
    }
    .image-left-wrapper {
      display: block;
    }
    .tabs {
      li {
        width: 50%;
        text-align: center;
      }
    }
  }

  .main-block-tabs {
    .product-image-right {
      img {
        padding-right: 5%;
        width: 100%;
        min-width: 17.7rem;
        min-height: 17.7rem;
      }
    }

    .product-image-left {
      padding: 0;
      padding-left: 5%;
      .image-left-wrapper {
        img {
          padding: 0 5%;
          margin-bottom: 10%;
          width: 100%;
        }
      }
      .card-title {
        display: none;
      }

      .card-text {
        display: none;
      }
    }
  }

  .tabs-container {
    padding-bottom: 10% !important;
    margin-bottom: 5% !important;
    border: none !important;
  }

  .text-part {
    // padding: 5rem 1rem !important;
    width: 100%;

    .h3 {
      line-height: 1.61;
    }
    p {
      text-align: left;
      margin-left: 1% !important;
      font-family: Montserrat;
      font-size: 17px;
      font-weight: 400;
      line-height: 1.67;
      letter-spacing: 1px;
      color: #49494f;
      margin-top: 2.5rem;
    }
  }
}

@media (max-width: 576px) {
  .main-block-tabs {
    .product-image-right {
      img {
        margin-top: 2%;
        padding-right: 5%;
        margin-left: 5% !important;
        width: 90% !important;
        min-width: 15.7rem;
        min-height: 15.7rem;
      }
    }

    .product-image-left {
      padding: 0;
      padding-left: 5%;
      .image-left-wrapper {
        img {
          padding: 0 5%;
          margin-bottom: 10%;
          width: 100%;
          object-fit: contain;
          height: auto;
        }
      }
    }
  }

  .tabs-section {
    .tabs-header {
      .tabs {
        ul {
          li {
            padding: 1rem 0;
          }
        }
      }
    }
    .tabs-container {
      .text-part {
        padding: 3rem 0;
      }
    }
  }
  .catalog-section {
    button {
      &.btn {
        justify-content: center;
      }
    }
  }
  .text-part-with-image {
    h2 {
      &.h1 {
        font-size: 2rem;
      }
    }
    .text-column {
      p {
        font-size: 1.4rem;
      }
    }
    h6 {
      &.h6 {
        font-size: 2rem;
      }
    }
    a {
      text-transform: uppercase;
    }
  }
  .flex-column-navigation-mobile-about {
    li {
      padding: 2rem 0 !important;
      width: 40% !important;
    }
  }
}
