.about-banner-desktop,
.about-banner-tab,
.about-banner-mob {
  p {
    img {
      max-height: 64.6rem;
      object-fit: cover;
      width: 100%;
    }
  }
}

// This scss is for design where every part of banner is separate

// .about-banner {
//   background-size: cover !important;
//   // min-height: 64.6rem;

//   .about-banner-desktop,
//   .about-banner-mob {
//     p {
//       img {
//         width: 100%;
//       }
//     }
//   }

//   .about-banner-mob {
//     img {
//       width: 100%;
//     }
//   }

//   .container {
//     max-width: calc(100% - 21px);
//   }

//   .banner-image-column {
//     display: flex;
//     justify-content: center;
//     img {
//       width: 100%;
//     }
//   }

//   .product {
//     padding-bottom: 8rem;
//   }

//   h1 {
//     font-size: 6.8rem;
//     color: #000;
//     z-index: 1;
//   }

//   .line {
//     height: 21.5rem;
//     position: absolute;
//     top: 50%;
//     left: 25%;
//     transform: translate(-50%, -50%);
//     -webkit-transform: translate(-50%, -50%);
//     -moz-transform: translate(-50%, -50%);
//     -ms-transform: translate(-50%, -50%);
//     -o-transform: translate(-50%, -50%);
//   }
// }

// @media (max-width: 1366px) {
//   .about-banner {
//     min-height: fit-content;
//   }
// }

// @media (max-width: 1024px) {
//   .about-banner {
//     .container {
//       max-width: calc(100% - 61px);
//     }
//     .banner-image-column img {
//       width: auto;
//       height: 40rem;
//     }

//     .banner-title-column {
//       h1 {
//         padding-bottom: 8rem;
//         width: 48%;
//         font-size: 4.8rem;
//       }
//       .line {
//         top: 15%;
//         left: 25%;
//       }
//     }
//   }
// }

// @media (max-width: 991px) {
//   .about-banner {
//     // min-height: 64.4rem;
//     .banner-title-column {
//       h1 {
//         width: 55%;
//         font-size: 4rem;
//         padding-bottom: 4rem;
//       }
//     }
//   }
// }

// @media (max-width: 767px) {
//   .about-banner {
//     .product {
//       padding-bottom: 0;
//     }

//     h1 {
//       font-size: 2.8rem !important;
//       padding-top: 6rem;
//       color: #211f44;
//       font-weight: 400;
//     }
//     .line {
//       top: 40%;
//       left: 45%;
//     }
//   }
// }

// @media (max-width: 480px) {
//   .about-banner {
//     min-height: 54rem;
//     .banner-image-column {
//       img {
//         width: 220px;
//         height: auto;
//       }
//     }
//     .banner-title-column {
//       h1 {
//         width: 85%;
//         padding-bottom: 10rem;
//         padding-top: 0;
//       }
//       .line {
//         top: 30%;
//         left: 50%;
//       }
//     }
//   }
// }
