.register-thank-you-page-wrapper {
  @media screen and(max-width:992px) {
    padding-top: 14rem;
  }
  @media screen and(max-width:600px) {
    padding-top: 9rem;
    padding-left: 0;
    padding-right: 0;
    .img-and-text-wrapper {
      padding-left: 0;
      padding-right: 0;
    }
  }
  padding-top: 22rem;
  .img-and-text-wrapper {
    width: 100%;
    text-align: center;
    img {
      height: 46rem;
      @media screen and(max-width: 1440px) {
        height: 50rem;
        width: 100%;
      }
      @media screen and(max-width: 992px) {
        height: 60rem;
        width: 100%;
      }
    }
    .text-wrapper {
      display: flex;
      flex-direction: column;
      @media screen and(max-width: 992px) {
        .buttons-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center !important;
          a {
            margin-bottom: 2rem;
          }
        }
      }
      .portfolio-link {
        margin-right: 4rem;
        @media screen and(max-width: 992px) {
          margin-right: 0;
        }
        button {
          color: #ffffff;
          height: 6rem;
          background: transparent !important;
          border: solid 2px #f29823;
          font-weight: 500;
        }
      }
      h1 {
        overflow: hidden;
        font-family: SangBleuSunriseRegular !important;
        font-size: 4.2rem;
        font-weight: 300;
        line-height: 1.38;
        letter-spacing: 2px;
        text-align: center;
        color: #ffffff;
        @media screen and(max-width: 992px) {
          font-size: 3rem;
        }
      }
      @media screen and(max-width: 992px) {
        top: 35rem;
      }
      @media screen and(max-width:600px) {
        top: 30rem;
      }
      top: 40rem;
      position: absolute;
      z-index: 99;
    }
  }
}
