.pdf-one-container {
  .container,
  .row,
  .col,
  .pdf-product-name,
  .pdf-productname,
  .separator,
  div,
  a {
    background-color: white !important;
  }
  .pdf-productname {
    font-size: 32px;
  }
}
.wishlist-container {
  #download-pdf-modal {
    .modal-body {
      > .row {
        width: 100%;
        .radio-btn.markup {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .textarea-pdf {
          display: flex;
          flex-direction: column-reverse;
          label {
            top: 3.7rem !important;
            width: 79px;
          }
        }
        &.pricing {
          .select2.markup-select {
            min-width: 150px;
            .react-select__control {
              min-width: 100px;
            }
          }
          .form-check {
            margin: 20px 0px;
            label {
              line-height: 21px !important;
            }
          }
        }
      }
    }
  }
  #bottom {
    border-top: 2px solid #e1e1e1;
    padding-top: 3.5rem;
    padding-bottom: 15px;
  }
  .project-modal {
    p {
      color: $blueLight;
      font-weight: 700;
    }
  }
  .container-fluid.header-padding-top.plp-wrapper {
    padding-top: 0;
    .select-wrapper.md-form.select-wob.d-flex.flex-row-reverse {
      align-items: center;
    }
  }

  .remove-project-modal {
    .modal-body {
      padding-top: 30px !important;
    }
    button {
      margin-bottom: 0 !important;
      line-height: 9px;
      &.cancel {
        width: 180px;
        height: 50px;
        padding: 16px 54px;
        margin-right: 25px;
      }
      &.confirm {
        width: 180px;
        height: 50px;
        padding: 16px 54px;
        border: solid 1px #000000;
        color: black;
        background-color: white !important;
        background: white !important;
        &:focus {
          background-color: white !important;
          background: white !important;
          color: black;
        }
      }
    }
  }

  .wishlist-projects {
    margin-top: 30px;
    .projects {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 2rem;
      grid-row-gap: 5rem;
      .project-wrapper {
        border: 1px solid #e1e1e1;
        margin: 0;
        // cursor: pointer;
        display: flex;
        align-items: center;
        max-height: 404px;
        overflow-y: hidden;
        justify-content: center;
        h5 {
          font-size: 2rem;
        }
        div.col-box {
          //   background-color: #f3f3f3;
          border: 1px solid #e1e1e1;
          outline: 1px solid #e1e1e1;
          box-shadow: 0 1px 0 #fff;
          padding: 1.5rem;
          height: 50%;
        }
        .box-img {
          width: 12.5rem;
          height: 12.5rem;
          margin: 0 auto;
        }
      }
    }
  }
  // padding: 20px;
  > .container {
    padding: 0 60px;
  }
  #favorites-tabs,
  #middle {
    height: 95px;
  }
  .wishlist-tab {
    border-color: $orange;
    padding: 2.5rem 0;
    font-size: 1.4rem;
    // font-family: "GothamBook";
    letter-spacing: 0.056rem;
    color: $blueLight;
    cursor: pointer;
    line-height: 2.2rem;
    text-transform: uppercase;
    font-weight: bold;
    min-width: 155px;
    justify-content: center;
    &.active {
      border-bottom: 0.2rem solid $orange;
    }
  }
  #listing {
    margin-top: 30px;
    .products-list {
      grid-template-columns: 1fr 1fr 1fr;
      &.tab_mobile {
        grid-template-columns: 1fr 1fr;
      }
      .wishlist-part {
        border-top: 1px solid #e1e1e1;
        padding: 25px 0px;
        border-bottom: 1px solid #e1e1e1;
      }
    }
  }
  div#middle {
    border-top: 1px solid $grayDark;
    border-bottom: 1px solid $grayDark;
    display: flex;
    align-items: center;
    > .row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .select-wob.select-wrapper {
        align-items: center;
        input.select-dropdown {
          max-width: 19rem;
        }
      }
    }
    h5 {
      font-size: 2.25rem;
      margin-bottom: 0;
      color: $blueLight;
      align-items: flex-start;
      display: flex;
      > span {
        position: relative;
        top: 3px;
      }
      div {
        position: relative;
        top: 3px;
      }
      span {
        font-size: 1.2rem;
        position: relative;
        left: 5px;
      }
    }
  }
}

@media screen and (max-width: $screen__sm) {
  .wishlist-container {
    .products-list .single-product > div {
      min-height: auto;
      max-height: initial;
    }
    div#middle {
      > .row .select-wob.select-wrapper input.select-dropdown {
        max-width: 14rem;
      }
      h5 {
        flex-direction: column;
        > span {
          font-size: 1.6rem;
          top: 6px;
          left: 0;
        }
      }
    }
  }
}

@media screen and (max-width: $screen__l) {
  .wishlist-container {
    .wishlist-projects {
      #listing {
        .products-list {
          grid-template-columns: repeat(2, 1fr);
        }
      }
      .projects {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

@media screen and (max-width: $screen__m) {
  .wishlist-container {
    .wishlist-projects {
      .projects {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}

@media screen and (max-width: $screen__sm) {
  .wishlist-container div#middle h5 span {
    left: 0;
  }
}

@media screen and (max-width: $screen__s) {
  .wishlist-container {
    .wishlist-projects {
      .project-wrapper {
        div.col-box {
          height: 100% !important;
          display: flex;
          align-items: center;
          .box-img {
            height: auto;
            width: auto;
          }
        }
        > div:nth-child(3),
        > div:nth-child(4) {
          display: none;
        }
      }
    }
    div#favorites-tabs {
      padding: 0px 15px;
    }
    > .container {
      padding: 15px;
    }
  }
}

@media screen and (max-width: 576px) {
  .wishlist-container {
    padding: 0 25px;
    div#favorites-tabs {
      padding: 0px 15px;
    }
    #listing {
      .products-list {
        .wishlist-part {
          padding: 10px 0px;
          justify-content: space-between !important;
          .quote-holder {
            margin: 0;
          }
        }
      }
    }
    .row .select-wob.select-wrapper {
      input.select-dropdown {
        max-width: 10rem !important;
        font-size: 12px !important;
        font-weight: 300;
      }
      label.mdb-main-label.active.text-primary {
        position: relative;
        top: 0px !important;
        left: 48px !important;
      }
    }
  }
}

@media screen and (max-width: $screen__xs) {
  .wishlist-container {
    .wishlist-modal {
      button {
        padding: 16px 20px !important;
      }
    }
    .wishlist-tab {
      justify-content: flex-start;
    }
    div#middle {
      h5 {
        font-size: 1.8rem;
      }
    }
  }
}
