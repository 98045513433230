html,
body {
  font-size: 10px;
  height: 100%;

  #root {
    display: flex;
    flex-direction: column;
    height: 100%;
    .main {
      flex: 1 0 auto;
    }
    .page-footer {
      flex-shrink: 0;
    }
  }
}

@import "./style/variables";
@import "./style/typography";
@import "./style/typography/lists";
@import "layout/layout";
@import "pages/pages";
@import "core/core";
@import "style/global";

@font-face {
  font-family: "SangBleuSunriseRegular";
  src: local("SangBleuSunriseRegular"),
    url("../fonts/SangBleuSunrise-Regular.otf") format("truetype");
}

@font-face {
  font-family: "SangBleuSunriseS";
  src: local("SangBleuSunriseS"),
    url("../fonts/SangBleuSunrise-Regular-WebS.woff") format("truetype");
}

@font-face {
  font-family: "SangBleuSunriseXL";
  src: local("SangBleuSunriseXL"),
    url("../fonts/SangBleuSunrise-Regular-WebXL.woff") format("truetype");
}

.main {
  width: 100%;
  max-width: 3840px;
  margin: auto;
}

.fade-out {
  transition: all 0.2s ease-in-out;
  opacity: 0;
  pointer-events: none;
}

.closeout-item {
  // min-width: 40%;
  z-index: 5;
  cursor: pointer;
  position: absolute;
  left: 15px;
  background: #151521;
  color: white;
  top: 15px;
  line-height: 35px;
  font-size: 13px;
  width: 17.5rem;
  text-align: center;
}

.privacy-policy-page {
  padding-top: 15rem;
  background-image: url("./assets/images/privacy-policy-back.png");
  background-repeat: no-repeat;
  background-position: 0% 10%;
  margin: auto;

  .privacy-content {
    margin: auto;
    width: 80%;
    padding-top: 100px;
    padding-bottom: 100px;

    h1,
    h2 {
      font-family: SangBleuSunriseTrial;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      color: #211f44;
      border-bottom: solid 1px #b9bbca;
    }

    h1 {
      font-size: 42px;
      line-height: normal;
      letter-spacing: 1.5px;
      padding-bottom: 40px;
    }

    h2 {
      font-size: 30px;
      line-height: 1.7;
      letter-spacing: 1.25px;
      padding-bottom: 20px;
      margin-top: 32px;
      margin-bottom: 20px;
    }

    p {
      font-family: Montserrat;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: 1px;
      color: #49494f;
    }

    .privacy-list {
      padding-left: 20px;
      line-height: 2.2;
      margin-left: 20px;
      margin-top: 20px;

      li {
        font-size: 14px !important;
      }
    }
  }
}
