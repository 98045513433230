.description-wrapper {
  background-color: #fbfcf5;
  .md-accordion {
    .card {
      max-width: 100%;
    }
  }
  .data-row {
    max-width: calc(100% - 152px);
    margin: 0 auto;
    .description-and-size-wrapper {
      padding-right: 20rem;
      padding-bottom: 9rem;
      .custom-item-prima-web-drawing-wrapper {
        img {
          height: 57.6rem;
          width: 100%;
          object-fit: contain;
          @media screen and (max-width: 1366px) {
            height: 40rem;
          }
        }
      }
      @media screen and (max-width: 1501px) {
        padding-right: 5rem;
      }
      @media screen and (max-width: 768px) {
        padding: 0;
      }
      .nav-tabs {
        margin-top: 7.5rem;
        border: 1px solid #e1e1e1;
        .nav-item {
          width: 50%;
          .nav-link {
            text-align: center;
            color: #1f1e3f;

            &:focus {
              color: #000000;
              font-weight: normal;
              border-bottom: 3px solid #262639;
            }
            &.active {
              background-color: #d8d8d8;
              font-weight: normal;
            }
            color: #000000;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 6.4rem;
          }
        }
      }
      .tab-content {
        padding: unset;
        margin-top: 5rem;
        .tab-pane {
          > div {
            img {
              display: block;
              margin: 0 auto;
            }
            ul {
              font-family: "Montserrat";
              font-size: 1.8rem;
              @media screen and(max-width: 1281px) {
                font-size: 1.4rem !important;
              }
              li {
                margin-bottom: 2rem;
                line-height: 1.44;
                letter-spacing: 0.06px;
              }
            }
          }
        }
      }
    }
    .card-body-description {
      border-bottom: solid 1px #979797;
    }
    .description-title {
      .card-header h5 {
        font-family: SangBleuSunriseRegular, LucidaGrande, Montserrat;
        font-size: 28px;
        line-height: 1.04;
        letter-spacing: 1.5px;
        color: rgba($color: #211f44, $alpha: 0.9) !important;
      }
      .card-header {
        border-bottom: none !important;
        padding: 1rem 0;
        margin-bottom: 0 !important;
      }
    }
  }
}
