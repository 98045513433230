.faq-pages-wrapper {
  h1 {
    padding-left: 1rem;
    font-family: SangBleuSunriseRegular !important;
    font-size: 2.8rem;
    line-height: 10rem;
    letter-spacing: 1px;
    color: #211f44;
    margin-bottom: 0rem;
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 2rem;
    height: 10rem;
    vertical-align: middle;
  }
  .accordion {
    .collapse {
      margin-bottom: 4rem;
    }
    .pdf-wrapper {
      background-color: #ffffff;
      .download-text {
        float: none;
        color: #1f1e3f;
        font-size: 1.2rem;
        font-weight: normal;
        margin-top: 0;
        letter-spacing: 0;
      }
    }
    .download-steps-text {
      font-family: Montserrat;
      font-size: 1.2rem;
      font-weight: normal;
      letter-spacing: 0.07rem;
      color: #1f1e3f;
    }
    .download-steps-links {
      font-family: SangBleuSunrise;
      font-size: 1.6rem;
      color: #151522;
      width: fit-content;
      padding: 1rem;
      padding-left: 0;
      font-weight: 400;
      border-left: 3px solid transparent;
      transition: all 0.3s ease-in-out;
      &:hover {
        border-left: 3px solid #211f44;
        padding-left: 1rem;
        cursor: pointer;
        background-color: #f6f7ef;
      }
    }
    .answer-text {
      font-family: Montserrat;
      font-size: 1.8rem;
      font-weight: normal;
      line-height: 3rem;
      letter-spacing: 1px;
      color: #49494f;
      margin-bottom: 2rem;
    }
    .accordion-title {
      @media screen and(max-width: 600px) {
        font-size: 1.8rem;
        letter-spacing: 0;
      }
      // border-top: 1px solid #e1e1e1;
      width: 100%;
      font-family: SangBleuSunriseRegular;
      font-size: 2rem;
      font-weight: normal;
      height: auto;
      // line-height: 10rem;
      // padding-bottom: 0rem;
      letter-spacing: 0.1rem;
      color: #211f44;
      margin: 2rem 0;
    }
    border-bottom: 1px solid #e1e1e1;
    &:first-child {
      border-top: 1px solid #e1e1e1;
    }
  }
}
/*Media Queries*/
@media (max-width: 575.98px) {
  .faq-pages-wrapper {
    .answer-text {
      font-size: 1.6rem!important;
    }
    h1 {
      font-size: 2rem;
      line-height: initial;
      display: flex;
      align-items: center;
    }
  }
}
