.pagination-container {
  .page-number-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    cursor: pointer;
    p {
      font-family: "SangBleuSunriseRegular" !important;
      font-size: 1.6rem;
      line-height: 1.44;
      letter-spacing: normal;
      color: #000000;
      font-weight: normal;

      svg {
        margin-left: 1rem !important;
      }
    }

    ul {
      position: absolute;
      padding-top: 4rem !important;
      list-style-type: none;
      z-index: 8;
      color: #151522;
      width: 100%;
      padding: 0;
      margin-bottom: 0;
      max-height: 0;

      li {
        box-shadow: 1.2px 1.2px rgba(0, 0, 0, 0.5);
        padding-top: 1rem;
        display: flex;
        justify-content: center;
        background-color: #fbfcf4;
        color: black;

        font-size: 1.6rem;
        &:hover {
          border-left: 2px solid #211f44;
          background-color: #f6f7ef;
        }
      }
      .active {
        border-left: 2px solid #211f44;
        background-color: #f6f7ef;
      }
    }
    .show-more {
      &.disabled {
        opacity: 0.65;
      }
    }
  }
}

/*Media Queries*/
@media screen and(max-width: 1440px) {
  .pagination-container {
    display: flex;
    flex-direction: column;
    .page-number-container {
      margin-bottom: 1rem;
    }
    .pagination-inner {
      justify-content: center !important;
    }
  }
}
@media (max-width: 991.98px) {
  .pagination-container {
    .page-number-container {
      margin-bottom: 0;
      .show-more {
        &.show-more-bottom-true {
          margin-bottom: 1rem;
        }
      }
    }
    .pagination-inner {
      &.pagination-bottom-false {
        display: none !important;
      }
    }
  }
}
