.quote-form-select-2 {
  .select2 {
    border: solid 1px #979797 !important;
  }
  margin-top: 2rem;
  position: relative;
  padding-left: 0;
  padding-right: 0;
  .select2-label {
    position: absolute;
    font-weight: 300;
    text-transform: uppercase;
    margin-top: -0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    line-height: 1.4rem;
    background-color: #fbfcf5;
    z-index: 99;
    left: 3%;
    font-size: 1.2rem;
    color: #000000;
  }
  height: 5rem;
  .select2 {
    border: 0.5px solid #626262;
    font-size: 1.4rem;
    color: #353452;
    .react-select__control {
      background-color: transparent;
    }
    .react-select__placeholder {
      padding-left: 2rem;
    }
    .react-select__single-value {
      padding-left: 2rem;
    }
    .react-select__menu {
      border-radius: 0;
      border: 0.5px solid #626262;
      z-index: 100;
      margin-top: 0;
      border: none;
      width: 100%;
      background-color: #fbfcf5;
      box-shadow: 0 0 0px 1px #353452;
    }
  }
  @media screen and (max-width: 768px) {
    margin-top: 1rem;
  }
}
