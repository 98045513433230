.find-rep-card {
  padding-left: 1.4rem;
  @media screen and(max-width: 1500px) {
    height: unset;
  }

  @media screen and(max-width: 600px) {
    margin-top: 0;
  }
  margin-bottom: 1.5rem;
  width: 100%;
  background-color: #fbfcf5;
  @media screen and(max-width: 1500px) {
    width: auto;
  }
  .icon-wrapper {
    svg {
      margin-top: 0.5rem;
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      background-image: linear-gradient(60deg, #dde1f2 4%, #a2a5be 93%);
      object-fit: scale-down;
      margin-right: 0.8rem;
    }
    img {
      margin-top: 0.5rem;
      margin-right: 0.8rem;
      width: 5rem;
      height: 5rem;
      object-fit: contain;
      zoom: 1.4;
    }
    @media (max-width: 767.98px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .first-col,
  .second-col,
  .third-col {
    a:hover {
      font-weight: 500;
    }
    @media screen and (max-width: 1200px) {
      padding-bottom: 2rem;
      .third-col {
        overflow-x: hidden !important;
      }
    }
    h2,
    h6,
    a {
      font-weight: 400;
      text-decoration: none;
      font-family: Montserrat;
      color: #211f44;
      line-height: 2rem !important;
      margin-bottom: 0 !important;
    }
    h2 {
      @media screen and(max-width: 1500px) {
        font-size: 1.6rem;
      }

      font-weight: 400;
      font-family: SangBleuSunriseRegular;
      font-size: 2rem;
    }
    h6,
    a {
      font-weight: 400;
      font-size: 1.6rem;
      @media screen and(max-width: 1500px) {
        font-size: 1.4rem;
      }
    }
    @media (max-width: 767.98px) {
      text-align: center;
    }
  }
}
