@import "./baner/baner";
@import "./text-part/text-part";
@import "./text-part-with-image/text-part-with-image";
@import "./catalog-section/catalog";
@import "./tab-section/tabs";

.about-us {
  padding-top: 10rem;
}

@media (min-width: 1981px) {
  .about-us {
    .container {
      min-width: calc(100% - 70rem);
    }
  }
}

@media (min-width: 1400px) {
  .about-us {
    .container {
      max-width: 1290px;
    }
  }
}

@media (max-width: 991px) {
  .about-us {
    padding-top: 6.6rem;
  }
}
