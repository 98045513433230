.upcoming-main-container {
  margin-bottom: 20rem;
  .section-title {
    margin-bottom: 10rem;
    hr {
      width: 100%;
      border-top: $lightGrayBorder;
    }
    .h5 {
      white-space: nowrap;
      padding: 0 1.8rem;
      letter-spacing: 1.75px;
    }
  }
  .upcoming-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 6px;
    img {
      width: 100%;
    }

    .upcoming-date {
      position: absolute;
      right: 0;
      top: 0;
      padding: 3.5rem;
      background: #171626;
      text-align: center;
      .date-number {
        font-family: SangBleuSunriseRegular !important;
        text-align: center;
        color: #fff;
        font-size: 2.8rem;
        font-weight: 300;
        line-height: normal;
      }
      .date-name {
        font-size: 2rem;
        font-family: SangBleuSunriseRegular !important;
        font-weight: 300;
        line-height: normal;
        color: #fff;
      }
    }
    .upcoming-text {
      background: #fbfcf5;
      padding: 5rem 10rem;
      .upcoming-content-text {
        p:nth-child(1),
        p:nth-child(2) {
          font-family: SangBleuSunriseRegular !important;
          font-size: 2.4rem;
          font-weight: 200;
          color: #000000;
        }
        .small-text {
          margin-top: 2rem;
        }
        p {
          a {
            font-family: Montserrat;
            font-size: 1.6rem;
            font-weight: bold;
            padding: 0;
            margin: 4rem 0;
          }
        }
      }
    }
  }
}
@media (max-width: 1440px) {
  .upcoming-main-container {
    .upcoming-container {
      .imageloader {
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
@media (max-width: 1199px) {
  .upcoming-main-container {
    margin-bottom: 4.4rem;
    .section-title {
      margin-bottom: 4.4rem;
      hr {
        width: 100%;
        border-top: $lightGrayBorder;
      }
      .h5 {
        white-space: nowrap;
        padding: 0 1.8rem;
        letter-spacing: 1.75px;
        font-size: 2rem;
      }
    }
    .upcoming-container {
      display: grid;
      grid-template-columns: 1fr;
      gap: 0;
      img {
        width: 100%;
        height: 28rem;
        object-fit: cover;
      }

      .upcoming-date {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 3.5rem;
        background: #171626;
        text-align: center;
        .date-number {
          font-family: SangBleuSunriseRegular !important;
          text-align: center;
          color: #fff;
          font-size: 2.8rem;
          font-weight: 300;
          line-height: normal;
        }
        .date-name {
          font-size: 2rem;
          font-family: SangBleuSunriseRegular !important;
          font-weight: 300;
          line-height: normal;
          color: #fff;
        }
      }
      .upcoming-text {
        background: #fbfcf5;
        padding: 5rem 1.8rem;
        .upcoming-content-text {
          p:nth-child(1),
          p:nth-child(2) {
            font-family: SangBleuSunriseRegular !important;
            font-size: 2.2rem;
            font-weight: 200;
            color: #000000;
          }
          .small-text {
            margin-top: 2rem;
          }
          p {
            a {
              font-family: Montserrat;
              font-size: 1.6rem;
              font-weight: bold;
              padding: 0;
              margin: 4rem 0 0 0;
            }
          }
        }
      }
    }
  }
}
