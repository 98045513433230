@import "./item/miniQuoteRequestItem";
.quote-container {
  position: relative;
  .quote-box {
    max-height: 60rem;
    .romb {
      background: #fff;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      position: absolute;
      width: 2rem;
      height: 2rem;
      top: -9px;
      right: 10.5rem;
    }
    .quote-body {
      overflow-y: scroll;
    }
    .quote-body::-webkit-scrollbar {
      display: none;
    }
    z-index: 1000;
    width: 45rem;
    position: absolute;
    top: 1.9rem;
    right: -10rem;
    background: #fff;
    .quote-title {
      border-bottom: $lightGrayBorder;
      position: relative;

    }
    .quote-body {
      p {
        font-size: 1.6rem;
        line-height: 2;
        + {
          p {
            font-size: 1.4rem;
          }
        }
      }

      .btn {
        width: 100%;
        font-size: 1.4rem;
      }
    }
    .flex-column {
      background-color: #fff !important;
    }

    @media screen and (max-width: 576px){
      .romb {
        top:-9px;
        right:10.7rem;
      }
    }
  }


}
.product-image-mini {
  img {
    width: 25%;
    height: auto;
  }
}

.btn a {
  color: white;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.88px;
  text-align: center;
}
@media (max-width: 576px) {
  .quote-box {
    max-width: 97vw !important;
    right: -6.3 !important;
    top: 1.6rem !important;
    .card-title {
      h6 {
        font-size: 2rem;
      }
    }
  }
}
