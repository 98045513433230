.privacy-policy-outer {
  padding-top: 10rem;
  .privacy-policy {
    padding: 7rem 0 16rem;
    margin: 0 auto;
    background-size: contain;
    .h1 {
      color: #211f44;
      font-weight: 400;
      margin-bottom: 4rem;
    }
    .md-accordion {
      .card {
        background: transparent;
        min-width: 100%;
        &:first-child {
          border-top: 0.2rem solid #e1e1e1 !important;
        }
        .card-header {
          padding: 3rem 2rem;
          margin-bottom: 0;
          h5 {
            p {
              font-family: SangBleuSunriseRegular !important;
              color: #211f44;
            }
          }
        }
        .card-body {
          padding: 3rem 4rem;
          font-size: 1.8rem;
          min-width: 100%;
          p {
            margin-bottom: 2rem;
          }
        }
      }
    }
  }
}
/*Media Queries*/
@media (min-width: 1400px) {
  .privacy-policy {
    .container {
      max-width: 117rem;
    }
  }
}

@media (max-width: 991px) {
  .privacy-policy-outer {
    padding-top: 6.7rem;
  }
}

@media (max-width: 767px) {
  .privacy-policy-outer {
    .privacy-policy {
      background-size: contain !important;
      .h1 {
        font-size: 2.8rem;
      }
      padding: 5rem 0 10rem;
      .md-accordion {
        .card-header {
          padding: 2rem 1rem !important;
          h5 {
            font-size: 1.8rem;
          }
        }
        .card-body {
          font-size: 1.6rem !important;
          padding: 2rem !important;
        }
      }
    }
  }
}
@media (max-width: 575.98px) {
  .privacy-policy-outer {
    .privacy-policy {
      padding: 4rem 0 5rem;
      .h1 {
        font-size: 2rem;
        margin-bottom: 2rem;
      }
      p {
        font-size: 1.6rem;
      }
    }
  }
}
