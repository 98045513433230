.main-row-terms-conditions {
  padding-top: 10rem;
  .privacy-policy {
    padding: 10rem 0 16rem;
    margin: 0 auto;
    background-size: contain;
    .h1 {
      color: #211f44;
      font-weight: 400;
      margin-bottom: 4rem;
    }
    .md-accordion {
      .card {
        background: transparent;
        min-width: 100%;
        .card-header {
          padding: 3rem 2rem;
        }
        .card-body {
          padding: 3rem 4rem;
          font-size: 1.8rem;
          min-width: 100%;
        }
      }
    }

    h4 {
      font-size: 32px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1.14px;
      color: #211f44;
    }
  }
  .vertical-list li:not(:last-child):before {
    left: -15px;
    top: 1.8rem;
  }
  .vertical-list {
    li {
      font-size: 18px;
      line-height: 1.67 !important;
      letter-spacing: 1px !important;
      color: #49494f !important;
    }
  }
  .bottom-paragraph-row {
    a {
      font-size: 1.8rem;
      line-height: 1.67;
      letter-spacing: 1px;
      color: #49494f;
      &:hover {
        text-decoration: underline;
      }
    }
    margin-top: 5%;
  }
  .bottom-paragraph {
    margin-top: 2%;
    padding-left: 70px;
  }
  .md-accordion .card .card-header {
    border-bottom: none;
  }
  .md-accordion .card-header .fa:before {
    display: none;
  }
}
/*Media Queries*/
@media (max-width: 991.98px) {
  .main-row-terms-conditions {
    padding-top: 0;
  }
}
@media (max-width: 768px) {
  .main-row-terms-conditions {
    padding-top: 7rem;
    .privacy-policy {
      padding-top: 4rem !important;
    }
    .bottom-paragraph-row {
      display: none;
    }
    .h1 {
      border-bottom: 2px solid #e1e1e1;
      padding-bottom: 4rem;
      margin-bottom: 0 !important;
    }
    .md-accordion .card .card-header {
      border-bottom: 2px solid #e1e1e1;
    }
    .md-accordion .card-header .fa:before {
      display: block;
    }
  }
}
@media (max-width: 575.98px) {
  .main-row-terms-conditions {
    .privacy-policy {
      padding-bottom: 8rem;
      h1 {
        font-size: 2rem;
        padding-bottom: 3rem;
      }
      .md-accordion {
        .card {
          .card-header {
            padding: 1rem 2rem;
            h4 {
              font-size: 1.8rem;
            }
          }
        }
        .collapse {
          .card-body {
            padding: 2rem;
            ul {
              li {
                font-size: 1.4rem;
              }
            }
          }
        }
      }
    }
  }
}
