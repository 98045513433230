.stepped-cat-name-wrapper {
  h4 {
    @media screen and(max-width: 600px) {
      font-size: 2rem;
    }
  }
}
.stepped-wrapper {
  .step-title {
    padding-top: 2rem;
    margin-left: 10rem;
    @media screen and(max-width: 600px) {
      font-size: 1.8rem;
    }
  }
  .steps-numbers-box {
    border: 3px solid #f2ab21;
    border-radius: 50%;
    font-size: 5rem;
    color: #f2ab21;
    width: 7rem;
    height: 7rem;
    display: flex;
    justify-content: center;
    // align-items: center;
    position: absolute;
    @media screen and(max-width: 600px) {
      font-size: 3.5rem;
      width: 7rem;
      height: 7rem;
    }
  }
}
/*Media Queires*/
@media screen and(max-width: 1200px) {
  .stepped-wrapper {
    .cat-widget {
      h6 {
        font-size: 1.6rem;
      }
    }
  }
}
@media (max-width: 575.98px) {
  .stepped-wrapper {
    .step-title {
      padding-top: 0;
      margin-left: 6rem;
    }
    .steps-numbers-box {
      font-size: 2rem;
      width: 4rem;
      height: 4rem;
    }
    .steps-title-box {
      display: flex;
      align-items: center;
    }
    // .cat-widget {
    //   h6 {
    //     font-size: 1.6rem;
    //   }
    // }
  }
}
