.root {
  padding: 2rem 0;

  .header {
    margin-top: 4.5rem;
    font-size: 4rem;
  }
  .tabs {
    display: flex;
    height: auto;
    border: 1px solid #e1e1e1;
    background-color: transparent;
    width: fit-content;
    margin-top: 6rem;
    margin-bottom: 4rem;
  }
  .tab {
    padding: 1.5rem 3rem;
    font-size: 1.6rem;
    font-weight: 400;
    white-space: nowrap;
    text-transform: uppercase;
    cursor: pointer;
    color: #1f1e3f;
    max-width: 100%;
  }
  .selectedTab {
    font-weight: 500;
    color: #000;
    background-color: #f5f5f5;
  }

  .compliances-wrapper {
    display: flex;
    gap: 6rem;
    margin: 6rem 0;
  }
  .compliance-text {
    max-width: 60%;
    background-color: #f4f5f8;
    display: flex;
    align-items: center;
    padding: 4rem 3rem;
    position: relative;
    overflow: hidden;
    p {
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: #000;
      letter-spacing: 0.8px;
    }
  }

  .compliance-text::before {
    content: "";
    position: absolute;
    top: -10px;
    left: -9px;
    width: 25px;
    height: 25px;
    background-color: #000;
    border-radius: 50%;
  }
}
.root .complience-description-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 2rem;
  margin-bottom: 10rem;
}

.complience-description-subtitle {
  font-size: 1.8rem;
  font-weight: 600;
  color: #000;
  margin-bottom: 1rem;
}
.complience-description-text {
  font-size: 1.6rem;
  color: #000;
}

.baba-description-title {
  margin-top: 8rem;
  margin-bottom: 5rem;
  font-size: 3.2rem;
  width: 40%;
}
.baba-banner {
  margin-top: 10.4rem;
  .pagebuilder-mobile-only {
    display: none;
  }
}

@media screen and(max-width: 1366px) {
  .root {
    .tabs,
    .compliances-wrapper {
      flex-direction: column;
      width: 100%;
    }
    .compliance-text {
      max-width: 100%;
    }
    .complience-description-wrapper {
      grid-template-columns: auto;
    }
  }

  .baba-description-title {
    width: 100%;
    margin-top: 5rem;
  }

  .baba-banner {
    margin-top: 7rem;
  }
}
@media screen and(max-width: 768px) {
  .root {
    padding: 1.5rem;

    .header {
      margin-top: 10.4rem;
      font-size: 3rem;
    }
  }
  .tabs {
    margin-top: 3rem;
  }
  .tab {
    padding: 1.5rem 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .baba-description-title * {
    font-size: 2.2rem !important;
    line-height: 3rem;
  }
  .baba-banner {
    display: none;
  }
}
